import { Observable } from 'rxjs';
import { ResponseArray } from '@share/common/models/http';
import { API } from '@app/common/constants/api.directories';
import { ApiService } from '@app/common/services/api.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConsentsService {
  constructor(private apiService: ApiService) {}

  getList(name: string = ''): Observable<ResponseArray<any>> {
    return this.apiService.getAll<any>(`${API.AUTH.CONSENTS}/${name}`);
  }
}
