import { Injectable } from '@angular/core';
import { ROUTES_PATHS } from '@app/common/constants/routes-paths';
import { ResponseDefault } from '@share/common/models/http.model';
import { AppService } from '@share/common/services/app.service';
import { VersionService } from '@share/common/services/version.service';
import { VariablesCollection } from '@share/common/models/variables.model';
import { PortalLayoutService } from '@app/common/services/portal-layout.service';
import { Variable } from '@share/common/interfaces/variables.interface';
import { first } from 'rxjs/operators';
import { FacadeService } from '@app/common/services/facade.service';
import { AbstractData, ResponseObject } from '@share/common/models/http';

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {
  constructor(
    private appService: AppService,
    private versionService: VersionService,
    private portalLayoutService: PortalLayoutService,
    private facade: FacadeService
  ) {}

  getFrontendVersion(): Promise<any> {
    return this.versionService.getFrontendVersion().then(
      (version: any) => (this.versionService.frontend = version),
      () => {}
    );
  }

  getBackendVersion(): Promise<any> {
    return this.versionService.getBackendVersion().then(
      (response: ResponseDefault) => (this.versionService.backend = response.data.id),
      () => {}
    );
  }

  getTranslations(): Promise<any> {
    return this.facade.translator.load().then(
      (response: ResponseObject<any>) => (this.facade.translator.translations = response.data.attributes),
      () => this.onErrorOccur()
    );
  }

  checkMaintenance(): Promise<any> {
    this.facade.maintenance.maintenancePage = ROUTES_PATHS.maintenance.root;
    this.facade.maintenance.serverErrorPage = ROUTES_PATHS.notFoundPage.root;
    return this.facade.maintenance.check().then(
      response => this.facade.maintenance.onSuccess(response),
      error => this.facade.maintenance.onError(error)
    );
  }

  loadSettings(): Promise<any> {
    return new Promise<any>(resolve => {
      this.facade.settings.load().then(
        (response: ResponseObject<VariablesCollection>) => {
          this.facade.settings.variables = response.data.attributes;
          const layoutData = this.portalLayoutService.prepareData({
            ...response.data.attributes.layout,
            ...response.data.attributes.site
          });
          this.portalLayoutService.setLayout(layoutData as Array<AbstractData<Variable>>);
          this.facade.seo.setData(this.facade.settings.variables);
          resolve();
        },
        () => {
          this.facade.settings
            .loadDefaultVariables()
            .pipe(first())
            .subscribe(val => {
              this.facade.settings.variables = val;
              resolve();
            });
        }
      );
    });
  }

  getPermissions(): Promise<object> {
    //this.authService.isAuthenticated
    if (!!localStorage.getItem('token')) {
      return this.facade.crud.load();
    } else {
      return new Promise((resolve, reject) => resolve());
    }
  }

  private onErrorOccur() {
    this.appService.setFatalError(true);
    this.facade.router.navigate([ROUTES_PATHS.unknownErrorPage.root]);
  }
}
