import {Component, Input} from '@angular/core';

@Component({
  selector: 'shared-heading-section',
  templateUrl: './heading-section.component.html',
  styleUrls: ['./heading-section.component.scss']
})
export class HeadingSectionComponent {
  @Input() number: number = 0;
  @Input() subtitle: string = '';
  @Input() title: string;
  @Input() innerColor: boolean = false;
  @Input() hsClass: string;

}
