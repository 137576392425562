import { AfterContentChecked, ContentChildren, Directive, Input, QueryList, TemplateRef } from '@angular/core';

@Directive({selector: 'ng-template[appAccPanelTitle]'})
export class AccPanelTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({selector: 'ng-template[appAccPanelContent]'})
export class AccPanelContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({selector: '[appAccPanel]'})
export class AccPanelDirective implements AfterContentChecked {

  @Input() disabled = false;
  @Input() id: string;
  @Input() title: string;
  @Input() type: string;
  @Input() isOpen = false;

  @ContentChildren(AccPanelTitleDirective, {descendants: false}) titleTpls: QueryList<AccPanelTitleDirective>;
  @ContentChildren(AccPanelContentDirective, {descendants: false}) contentTpls: QueryList<AccPanelContentDirective>;

  titleTpl: AccPanelTitleDirective | null;
  contentTpl: AccPanelContentDirective | null;

  constructor() { }

  ngAfterContentChecked() {
    this.titleTpl = this.titleTpls.first;
    this.contentTpl = this.contentTpls.first;
  }
}
