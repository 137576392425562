import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styles: []
})
export class ContainerComponent implements OnInit {

  @Input() public top: number = 30;
  @Input() public bottom: number = 30;
  @Input() public left: number = 0;
  @Input() public right: number = 0;

  constructor() { }

  ngOnInit() {
  }

}
