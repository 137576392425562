import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ComponentHelper } from '@app/common/helpers/component.helper';
import { SettingsService } from '@app/common/services/settings.service';
import { CookieService } from '@app/client-core/cookies/service/cookie.service';

@Component({
  selector: 'app-cookie-information-bar',
  templateUrl: './cookie-information-bar.component.html',
  styleUrls: ['./cookie-information-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieInformationBarComponent extends ComponentHelper implements OnInit {

  public cookieText: string = '';

  constructor(private settings: SettingsService, private cookieService: CookieService) {
    super();
  }

  get isCookieAccepted(): string {
    return this.cookieService.getCookie('cookiesAccepted');
  }

  ngOnInit() {
    try {
      this.cookieText = this.settings.variables.footer.cookieWarningContent;
    } catch (ignore) {
      throw new Error('Cannot get cookieWarningContent of undefined');
    }
  }

  accept(): void {
    this.cookieService.setCookie('cookiesAccepted', 'true', 365);
  }

}
