<div class="overlay">
  <app-breadcrumbs></app-breadcrumbs>
  <cms-subheader-title [title]="'Kontakt'"></cms-subheader-title>
  <div>
    <h3 class="sr-only">Dane kontaktowe</h3>
    <ng-container *ngIf="institution">
      <p class="name">{{institution.attributes.name}}</p>
      <p>{{institution.attributes.address.zipCode}} {{institution.attributes.address.city}} </p>
      <p>{{institution.attributes.address.street}} {{institution.attributes.address.houseNumber}}</p>
      <p>tel.: {{institution.attributes.contact.phoneNumber}}</p>
      <p>{{institution.attributes.contact.email}}</p>
    </ng-container>
  </div>
</div>
