<section>
  <h2>{{ title }}</h2>
  <div class="text" [innerHTML]="subtitle | safe: 'html'"></div>

  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-lg-10">
        <app-input formControlName="senderName" [label]="'contact.form.name' | trans" [id]="'senderName'"></app-input>
      </div>
      <div class="col-lg-10">
        <app-input
          formControlName="senderEmail"
          [label]="'contact.form.email' | trans"
          [id]="'senderEmail'"
        ></app-input>
      </div>
      <div class="col-lg-12">
        <app-textarea
          formControlName="content"
          [label]="'contact.form.message' | trans"
          [id]="'content'"
        ></app-textarea>
      </div>
    </div>

    <div class="form-footer">
      <shared-checkbox
        *ngIf="formConfig.consent"
        [config]="formConfig?.consent"
        formControlName="dataProcessConsent"
      ></shared-checkbox>
      <cms-button [id]="'contact-form-btn'" [loading]="sendLoader" (clicked)="onSubmit()" text="Wyślij" icon="fas fa-paper-plane"></cms-button>
    </div>
  </form>
</section>
<app-contact-dots></app-contact-dots>
