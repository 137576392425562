<app-container>
  <cms-content [loading]="getLoading" [error]="getError" [value]="page" (componentRefreshed)="loadPage()">
    <div id="static-page" *ngIf="page">
      <div id="content-view">
        <ng-container [ngSwitch]="page.attributes.content?.pageType">
          <div class="container">
            <app-static-page-default
              *ngSwitchCase="'default'"
              [data]="page.attributes.content"
            ></app-static-page-default>
          </div>
          <app-schedule-page *ngSwitchCase="'schedule'" [data]="page.attributes.content"></app-schedule-page>
          <app-static-page-contact *ngSwitchCase="'contact'" [data]="page.attributes.content.content"></app-static-page-contact>
        </ng-container>
      </div>
    </div>
  </cms-content>

  <div class="contact-box" *ngIf="page?.attributes?.content?.pageType === 'default'">
    <app-simple-box></app-simple-box>
  </div>
</app-container>
