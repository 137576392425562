import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubheaderService } from '@app/template/layout/modules/header/services/subheader.service';
import { ElementService } from '@app/client-core/dynamic-content/service/element.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlService } from '@app/common/services/url.service';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { AbstractData, ResponseObject } from '@share/common/models/http';
import { PagesService } from '@app/common/services/pages.service';
import { PageModel } from '@app/common/models/page.model';
import { ReplaySubject } from 'rxjs';
import { HistoryService } from '@share/common/services/history.service';
import { StaticPage } from '@app/client-core/static-pages/model/static-pages.model';
import { Nullable } from '@share/common/types/utilities.types';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss']
})
export class StaticPageComponent implements OnInit, OnDestroy {
  @Input() page: Nullable<AbstractData<PageModel<StaticPage<any>>>>;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  getError: boolean;
  getLoading: boolean;

  constructor(
    protected pagesService: PagesService,
    protected subheaderService: SubheaderService,
    private elementService: ElementService,
    protected route: ActivatedRoute,
    private urlService: UrlService,
    private historyService: HistoryService,
    private router: Router
  ) {}

  private setListener() {
    this.elementService.sameComponentNavigation.pipe(takeUntil(this.destroyed$)).subscribe(same => {
      if (same) {
        this.loadPage();
      }
    });
  }

  ngOnInit() {
    this.setListener();
    if (!this.page) {
      this.loadPage();
    } else {
      this.setSubheader(this.page);
    }
  }

  protected onLoadPageSuccess(response: ResponseObject<PageModel<StaticPage<any>>>) {
    this.page = response.data;
    this.setSubheader(this.page);
  }

  private setSubheader(page: AbstractData<PageModel<StaticPage<any>>>) {
    this.subheaderService.setConfig({ title: page.attributes.title, publishedDate: page.attributes.publishedFrom });
    if (page.attributes.content?.pageType === 'schedule') {
      this.subheaderService.description = page?.attributes?.content?.content?.subtitle;
      this.subheaderService.publishedDate = '';
    }
    this.subheaderService.config.displayBreadcrumbs.next(true);
  }

  loadPage() {
    this.getLoading = true;
    this.pagesService
      .getOne(this.router.url.split('/').join(','))
      .pipe(
        first(),
        finalize(() => (this.getLoading = false))
      )
      .subscribe(
        response => this.onLoadPageSuccess(response),
        error => {
          this.subheaderService.setConfig({
            title: error.error.errors[0].status
          });
          this.subheaderService.description = error.error.errors[0].title;
          this.page = null;
        }
      );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
