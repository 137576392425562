import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-promoted-item-icon',
  styleUrls: ['promoted-item-icon.component.scss'],
  templateUrl: 'promoted-item-icon.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PromotedItemIconComponent {

  @Input() icon: string;

}
