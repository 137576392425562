import { Injectable } from '@angular/core';
import { LayoutService } from '@share/common/services/layout.service';
import { PortalVariablesEnum } from '@app/common/enums/portal-variables.enum';
import { PORTAL_VARIABLES_PRESETS } from '@app/common/constants/portal-variables-presets';
import { HttpClient } from '@angular/common/http';
import { ImageService } from '@share/common/services/image.service';
import { AbstractData } from '@share/common/models/http.model';
import { ObjectString } from '@share/common/interfaces/object-types.interface';
import { Variable } from '@share/common/interfaces/variables.interface';

@Injectable({
  providedIn: 'root'
})
export class PortalLayoutService extends LayoutService {

  constructor(protected httpClient: HttpClient,
              protected imageService: ImageService) {
    super(httpClient, imageService);
    this.layoutPresets = PORTAL_VARIABLES_PRESETS;
  }

  public prepareData(variables: ObjectString): Array<AbstractData<Partial<Variable>>> {
    const data: Array<AbstractData<Partial<Variable>>> = [];
    for (const variable of Object.keys(variables)) {
      data.push({
        id: '0',
        type: '',
        attributes: {
          name: variable,
          value: variables[variable]
        }
      });
    }
    return data;
  }

  protected variablesQueue(): Array<string> {
    return [
      PortalVariablesEnum.AccentColor,
      PortalVariablesEnum.ButtonColor,
      PortalVariablesEnum.MainColor,
      PortalVariablesEnum.SectionBackgroundColor,
    ];
  }
}
