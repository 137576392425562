import { ServiceHelper } from '@share/common/helpers/service.helper';
import { Bundle } from '@app/common/enums/server-bundles.enum';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AbstractData, ResponseArray } from '@share/common/models/http';
import { Breadcrumbs } from '@app/template/layout/modules/header/models/breadcrumbs.model';
import { API } from '@app/common/constants/api.directories';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService extends ServiceHelper {
  reload: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  clear: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  customBreadcrumbs: Array<{ title: string; url?: string }> = [];
  forceActive = false;

  constructor() {
    super(Bundle.Breadcrumbs);
  }

  public getList(url: string): Observable<ResponseArray<Breadcrumbs>> {
    return this.getAll<Breadcrumbs>(`${API.COMMON.BREADCRUMBS}/,${url}`);
  }

  public getParsedUrl(url: string): string {
    return url.substr(1, url.length).split('/').join(',');
  }

  public generateLink(breadcrumbs: Array<AbstractData<Breadcrumbs>>, index: number): Array<string> {
    let path: Array<string> = [];
    try {
      for (let i = 0; i < (index + 1); i++) {
        path.push(breadcrumbs[i].attributes.url);
      }
    } catch (_ignore) {
      path = [];
    }
    return path;
  }

  public reset() {
    this.reload.next(true);
  }
}
