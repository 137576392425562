import { NgModule } from '@angular/core';
import { ImageZoomComponent } from '@app/template/elements/image-zoom/components/image-zoom/image-zoom.component';
import { ProgressBarModule } from '@share/modules/progress-bar/progress-bar.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    ProgressBarModule
  ],
  declarations: [
    ImageZoomComponent
  ],
  exports: [
    ImageZoomComponent
  ],
  entryComponents: [
    ImageZoomComponent
  ]
})
export class ImageZoomModule {}
