import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractData } from '@share/common/models/http';
import { MultiCarousel } from '@app/template/elements/carousel/models/multi-carousel.model';
import { MultiCarouselConfig } from '@app/template/elements/carousel/models/multi-carousel-config.model';
import { ImageCropper } from '@app/template/home/models/image-cropper.model';

@Component({
  selector: 'app-gallery',
  templateUrl: 'gallery.component.html',
  styleUrls: ['gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent {
  config: MultiCarouselConfig = {
    dots: false,
    nav: true,
    itemsOnScreen: {
      0: {
        items: 2
      },
      700: {
        items: 2
      },
      800: {
        items: 2
      },
      1140: {
        items: 3
      },
      1370: {
        items: 4
      }
    },
    autoplayHoverPause: false,
    autoplay: false,
    autoplayTimeout: 5000,
    loop: false
  };

  @Input() set data(value: { gallery: AbstractData<{ image: ImageCropper; alt: string }>[] }) {
    if (value) {
      this.parsedData = value.gallery.map(item => ({
        id: item.id,
        type: item.type,
        attributes: {
          file: item.attributes.image.url || (item.attributes.image.croppedUrl as string),
          description: item.attributes.alt,
          url: '',
          isOpenInNewWindow: false
        }
      }));
    }
  }
  parsedData: Array<AbstractData<MultiCarousel>> = [];
}
