import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { SearchType, SubheaderConfig } from '@app/template/layout/modules/header/interfaces/subheader-config.interface';

@Injectable({
  providedIn: 'root'
})
export class SubheaderService {
  private _config: BehaviorSubject<SubheaderConfig>;

  constructor() {
    this._config = new BehaviorSubject<SubheaderConfig>(this.default);
  }

  private get default(): SubheaderConfig {
    return {
      title: '',
      description: '',
      searchType: SearchType.None,
      component: null,
      displayHeader: true,
      displayBreadcrumbs: new ReplaySubject<boolean>(1),
      searchOpen: false,
      hideSearch: false,
      injector: null,
      forceShow: false,
      categories: [],
      hideDots: false
    };
  }

  public get config(): SubheaderConfig {
    return this._config.getValue();
  }

  public getConfig(): Observable<Partial<SubheaderConfig>> {
    return this._config.asObservable();
  }

  public setConfig(value: Partial<SubheaderConfig>) {
    this._config.next({ ...this.default, ...value });
  }

  public set title(title: string) {
    this._config.next({
      ...this._config.getValue(),
      title
    });
  }

  public set description(description: string) {
    this._config.next({
      ...this._config.getValue(),
      description
    });
  }

  public set searchType(searchType: SearchType) {
    this._config.next({
      ...this._config.getValue(),
      searchType
    });
  }

  public set searchOpen(searchOpen: boolean) {
    this._config.next({
      ...this._config.getValue(),
      searchOpen
    });
  }

  public set injector(injector: Injector) {
    this._config.next({
      ...this._config.getValue(),
      injector
    });
  }

  public set forceShow(forceShow: boolean) {
    this._config.next({
      ...this._config.getValue(),
      forceShow
    });
  }

  public set displayBreadcrumbs(show: boolean) {
    this.config.displayBreadcrumbs.next(show);
  }

  set categories(categories: { slug: string; title: string }[]) {
    this._config.next({
      ...this._config.getValue(),
      categories
    });
  }

  set publishedDate(publishedDate: string) {
    this._config.next({
      ...this._config.getValue(),
      publishedDate
    });
  }

  set image(image: string) {
    this._config.next({
      ...this._config.getValue(),
      image
    });
  }

  set hideDots(hideDots: boolean) {
    this._config.next({
      ...this._config.getValue(),
      hideDots
    });
  }

  public reset() {
    this.setConfig(this.default);
  }
}
