import { Injectable } from '@angular/core';
import { ServiceHelper } from '@share/common/helpers/service.helper';
import { Bundle } from '@app/common/enums/server-bundles.enum';
import { Observable } from 'rxjs';
import { ResponseArray } from '@share/common/models/http.model';
import { API } from '@app/common/constants/api.directories';
import { Promoted } from '@app/template/elements/promoted/models/promoted.model';

@Injectable({
  providedIn: 'root'
})
export class PromotedService extends ServiceHelper {
  constructor() {
    super(Bundle.Promoted);
  }

  public getList(): Observable<ResponseArray<Promoted>> {
    return this.getAll<Promoted>(API.PROMOTED.ROOT);
  }
}
