import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FacadeService } from '@app/common/services/facade.service';
import { PagesService } from '@app/common/services/pages.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-simple-box',
  styleUrls: ['simple-box.component.scss'],
  templateUrl: 'simple-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleBoxComponent implements OnInit {
  data: {
    button: string;
    contactBoxIcon: string;
    contactPage: string;
    content: string;
    title: string;
  };

  constructor(private facadeService: FacadeService, private pageService: PagesService) {}

  ngOnInit() {
    this.data = this.facadeService.settings.variables.contactBox;
  }

  onButtonClick() {
    this.pageService
      .getOne(this.data.contactPage)
      .pipe(first())
      .subscribe(page => this.facadeService.router.navigate([page.data.id.replace(',', '/')]));
  }
}
