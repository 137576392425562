import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: 'input.component.html',
  styleUrls: ['input.component.scss']
})
export class InputComponent implements ControlValueAccessor {

  @Input() label: string;
  @Input() id: string;

  private onChange: (v: string) => void = (v: string) => {};
  private onTouch: () => void = () => {};

  value: string;

  constructor(private ngControl: NgControl) {
      this.ngControl.valueAccessor = this;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.onChange(obj);
  }
}
