<header
  id="main-app-header"
  class="d-flex"
  [ngClass]="{ subpage: isSubpage }"
>
  <h1 class="sr-only">Dolina baryczy</h1>
  <div class="content">
    <app-logo [class.hide]="isMobileSearchActive$ | async"></app-logo>
    <div
      class="search"
      [class.mobileSearchActive]="isMobileSearchActive$ | async"
      [class.mobile]="isMobileMenuMode"
    >
      <h2 class="sr-only">{{ 'global.search' | trans }}</h2>
      <app-search [mobile]="isMobileMenuMode"></app-search>
    </div>
    <div class="font-size-wcag">
      <h2 class="sr-only">{{ 'global.settings' | trans }}</h2>
      <app-a11y-font-size></app-a11y-font-size>
    </div>
    <div class="contrast-wcag">
      <h2 class="sr-only">{{'global.contrast' | trans}}</h2>
      <app-high-contrast></app-high-contrast>
    </div>
    <div class="menu">
      <app-default-menu
        *ngIf="!isMobileMenuMode && isValidDefaultMenuWidth"
        [menu]="menuData"
        [isLoading]="isFetchMenuLoading || isDefaultMenuWidthChecking"
        (refreshed)="onComponentRefresh()"
      ></app-default-menu>
      <app-mobile-menu
        *ngIf="isMobileMenuMode || !isValidDefaultMenuWidth"
        [menu]="menuData"
      ></app-mobile-menu>
    </div>
    <div class="ue-logo">
      <img src="/assets/source/img/ue-logo-vertical.png" [alt]="'header.ueLogo' | trans" />
    </div>
  </div>
</header>
