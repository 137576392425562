import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { Promoted } from '../../models/promoted.model';
import { ComponentHelper } from '@app/common/helpers/component.helper';
import { AbstractData } from '@share/common/models/http';

@Component({
  selector: 'app-promoted-list',
  templateUrl: './promoted-list.component.html',
  styleUrls: [`./promoted-list.component.scss`]
})
export class PromotedListComponent extends ComponentHelper implements OnDestroy {
  @Input() data: Array<AbstractData<Promoted>>;

  @Input() filter: string;
  @Input() height: number = 420;
  @Input() background?: string = '';
  @Input() hasIcon: boolean = false;
  @Output() readonly clicked: EventEmitter<string> = new EventEmitter();
  private listenClickFunc: Function;

  constructor(private renderer: Renderer2, private _elementRef: ElementRef) {
    super();
    this.onClickListener();
  }

  private onClickListener(): void {
    this.listenClickFunc = this.renderer.listen(this._elementRef.nativeElement, 'click', event =>
      this.shareService.utils.navigateTo(event)
    );
  }

  public imagePath(path: string) {
    return this.shareService.image.img({ file: path, filter: this.filter });
  }

  public navigateTo(event: Event, url: string, id: string) {
    event.preventDefault();
    event.stopPropagation();
    window.open(url);
    this.onClick(id);
  }

  public onClick(slug: string) {
    this.clicked.emit(slug);
  }

  public ngOnDestroy() {
    this.listenClickFunc();
  }

  public get dataExists(): boolean {
    return Array.isArray(this.data) && this.data.length > 0;
  }
}
