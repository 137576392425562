import { Injectable } from '@angular/core';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleTranslateService {
  public init() {
    // tslint:disable-next-line:no-unused-expression
    new google.translate.TranslateElement({
      pageLanguage: 'pl',
      includedLanguages: 'pl,cs,da,de,en,es,fi,fr,hu,it,ja,no,pt,ru,zh-CN',
      layout: google.translate.TranslateElement.InlineLayout.SIMPLE
    }, 'google_translate_element');
  }
}
