<section>
  <div class="row no-gutters">
    <div class="col-lg-5">
      <div class="left-content">
        <h2>{{ data?.title }}</h2>
        <div class="text">{{ data?.content }}</div>
      </div>
    </div>
    <div class="col-lg-7">
      <div class="right-content">
        <div class="contact">
          <h3>{{ data?.contactOne?.title }}</h3>
          <div>
            <i class="fas fa-phone"></i>
            <a [href]="'tel:' + data?.contactOne.phone">{{ data?.contactOne.phone }}</a>
          </div>
          <div>
            <i class="fas fa-clock"></i>
            {{ data?.contactOne.officeHours }}
          </div>
        </div>
        <div class="contact">
          <h3>{{ data?.contactTwo?.title }}</h3>
          <div>
            <i class="fas fa-phone"></i>
            <a [href]="'tel:' + data?.contactTwo?.phone">
              {{ data?.contactTwo?.phone }}
            </a>
          </div>
          <div>
            <i class="fas fa-clock"></i>
            {{ data?.contactTwo?.officeHours }}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
