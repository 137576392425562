import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Article } from '@app/client-core/article/models/article.model';
import { ArticlesService } from '@app/client-core/article/services/articles.service';
import { AbstractData, ResponseObject } from '@share/common/models/http';
import { ImageZoomService } from '@app/common/services/image-zoom.service';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { FacadeService } from '@app/common/services/facade.service';
import { ElementService } from '@app/client-core/dynamic-content/service/element.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-article',
  templateUrl: 'article.component.html',
  styleUrls: ['article.component.scss']
})
export class ArticleComponent implements OnInit, OnDestroy {
  protected slug: string = '';
  article: AbstractData<Article> | null = null;
  loading: boolean;
  getError: boolean;
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected route: ActivatedRoute,
    protected articleService: ArticlesService,
    protected imageZoomService: ImageZoomService,
    protected cdr: ChangeDetectorRef,
    protected facadeService: FacadeService,
    private elementService: ElementService
  ) {
    this.setSlug();
  }

  setLoader(value: boolean) {
    this.loading = value;
    this.cdr.detectChanges();
  }

  protected setSlug() {
    const elements: UrlSegment[] = this.route.snapshot.url;
    this.slug = this.route.snapshot.url[elements.length - 1].path;
  }

  ngOnInit() {
    this.loadArticle();
    this.elementService.sameComponentNavigation.pipe(takeUntil(this.destroyed$)).subscribe(() => this.loadArticle());
  }

  loadArticle() {
    this.setLoader(true);
    this.article = null;
    this.setSlug();
    this.articleService
      .getOne(this.slug)
      .pipe(
        first(),
        finalize(() => this.setLoader(false))
      )
      .subscribe(
        response => this.onLoadArticleSuccess(response),
        () => (this.getError = true)
      );
  }

  protected onLoadArticleSuccess(response: ResponseObject<Article>) {
    this.article = response.data;
    this.facadeService.subheader.config.title = this.article.attributes.title;
    this.facadeService.subheader.config.displayBreadcrumbs.next(true);
    this.facadeService.subheader.categories = this.article.attributes.categories;
    this.facadeService.subheader.publishedDate = this.article.attributes.createdAt;
    this.facadeService.subheader.image = this.article.attributes.file || this.article.attributes.file['croppedImage'];
    this.imageZoomService.setListener();
    this.facadeService.scrollTo.resetScrollTop();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
