import { AfterViewInit, Component, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicContentService } from '@app/client-core/dynamic-content/service/dynamic-content.service';
import { LazyLoaderService } from '@app/common/services/lazy-loader.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { ROUTES_PATHS } from '@app/common/constants/routes-paths';
import { FacadeService } from '@app/common/services/facade.service';
import { ComponentInterface } from '@app/client-core/dynamic-content/interfaces/components.interface';
import { Nullable } from '@share/common/types/utilities.types';
import { BreadcrumbsService } from '@app/template/layout/modules/header/services/breadcrumbs.service';
import { ElementService } from '@app/client-core/dynamic-content/service/element.service';
import { SettingsService } from '@app/common/services/settings.service';
import { HOME_COMPONENTS } from '@app/client-core/dynamic-content/constants/component-list';

@Component({
  selector: 'app-dynamic-content',
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.scss']
})
export class DynamicContentComponent implements AfterViewInit, OnDestroy {
  @ViewChild('componentPlaceholder', { read: ViewContainerRef }) componentPlaceholder: ViewContainerRef;
  private currentView: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dynamic: DynamicContentService,
    private router: Router,
    private lazyLoaderService: LazyLoaderService,
    private facadeService: FacadeService,
    private breadcrumbsService: BreadcrumbsService,
    private elementsService: ElementService,
    private settingsService: SettingsService,
  ) {}

  async ngAfterViewInit() {
    this.dynamic.runtimeUrlReader$
      .pipe(
        takeUntil(this.destroy$),
        switchMap((rawUrl: string) => {
          if (rawUrl.includes('#')) {
            return of(null);
          } else {
            if (rawUrl === '/') {
              return of(this.getHomeComponent()).pipe(
                tap((component: Nullable<ComponentInterface>) => (this.dynamic.homeComponent = component))
              );
            }
            return this.dynamic.componentLoader(rawUrl);
          }
        })
      )
      .subscribe(
        (component: Nullable<ComponentInterface>) => {
          this.loadView(component);
        },
        () => this.componentNotFoundNavigation()
      );
  }

  private getHomeComponent(): ComponentInterface {
    const { name } = this.settingsService.variables.theme;
    return { ...HOME_COMPONENTS[name], isHome: true };
  }

  private loadView(component: Nullable<ComponentInterface>) {
    if (component && component.className !== this.currentView) {
      this.breadcrumbsService.reset();
      this.facadeService.subheader.reset();
      this.componentPlaceholder?.clear();
      this.lazyLoaderService.load(component.module, this.componentPlaceholder, component.name).then(() => {
        this.facadeService.scrollTo.resetScrollTop();
        this.dynamic.loaded = true;
        this.currentView = component?.className;
      });
    } else if (component) {
      this.navigateToHomeDefaultContext(component);
      this.breadcrumbsService.reset();
      this.dynamic.loaded = true;
      this.elementsService.loaded = true;
    }
    this.facadeService.scrollTo.resetScrollTop();
  }

  private navigateToHomeDefaultContext(component: null | ComponentInterface) {
    if (component?.defaultContext && component?.isHome) {
      this.router.navigate([component?.defaultContext]);
    }
  }

  private componentNotFoundNavigation() {
    this.router.navigate(['/', ROUTES_PATHS.notFoundPage.root]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
