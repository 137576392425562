import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicContentComponent } from './component/dynamic-content/dynamic-content.component';
import { ProgressBarModule } from '@share/modules/progress-bar/progress-bar.module';
import { StaticPagesModule } from '@app/client-core/static-pages/static-pages.module';
import { ArticleModule } from '@app/client-core/article/article.module';
import { HomeModule } from '@app/template/home/home.module';
import { DynamicUtilsService } from '@app/client-core/dynamic-content/service/dynamic-utils.service';

@NgModule({
  declarations: [DynamicContentComponent],
  imports: [
    CommonModule,
    ProgressBarModule,
    StaticPagesModule,
    ArticleModule,
    HomeModule
  ],
  providers: [DynamicUtilsService],
  exports: [
    DynamicContentComponent
  ]
})
export class DynamicContentModule {
}
