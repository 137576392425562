import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PortalLayoutService } from '@app/common/services/portal-layout.service';
import { ComponentHelper } from '@app/common/helpers/component.helper';
import { HistoryService } from '@share/common/services/history.service';
import {AppConfigCMS} from '@share/common/interfaces/app-config.interface';

declare const APP_CONFIG: AppConfigCMS;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends ComponentHelper implements AfterViewInit, OnInit {
  title = 'MPortal';
  loading = false;

  constructor(private portalLayoutService: PortalLayoutService,
              private routingState: HistoryService) {
    super();
  }

  public ngOnInit() {
    this.subscriptions.add(
      this.routingState.setHistoryListener()
    );
    this.injectGoogleAnalytics();
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.portalLayoutService.setCssVarsForIE();
    }, 1);
  }

  private injectGoogleAnalytics() {
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${APP_CONFIG.GTM}');`;
    document.head.appendChild(script);
  }
}
