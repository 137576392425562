import { ChangeDetectionStrategy, Component } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-a11y-font-size',
  styleUrls: ['a11y-font-size.component.scss'],
  templateUrl: 'a11y-font-size.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class A11yFontSizeComponent {
  constructor() {}

  setDefaultFontSize() {
    this.changeFontSize('10px');
  }
  setBiggerFontSize() {
    this.changeFontSize('11px');
  }
  setBigFontSize() {
    this.changeFontSize('12px');
  }

  private changeFontSize(size: string) {
    $('html').css({
      'font-size': size
    });
  }
}
