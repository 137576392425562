<div id="error-pages">
  <div class="error-content">
    <div class="error-content">
      <h1>{{ '404' }}</h1>
      <p>{{ 'Nie znaleziono strony' }}</p>
      <cms-button
        [id]="'not-found-button'"
        [text]="'Powrót do strony głównej'"
        (clicked)="goToHome()"
        [icon]="buttonIcons.ChevronLeft"
        [btnClass]="[buttonClasses.Primary]"
        [type]="buttonTypes.Button"
      ></cms-button>
    </div>
  </div>
</div>

