<div class="article-content">
  <cms-content [loading]="loading" [error]="getError" [value]="articleList" (componentRefreshed)="loadArticles()">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-5" *ngFor="let article of articleList">
        <app-article-list-item
          [title]="article.attributes.title"
          [description]="article.attributes.description"
          [publishedFrom]="article.attributes.publishedFrom"
          [categories]="article.attributes.categories"
          [file]="article.attributes.file"
          [slug]="article.attributes.slug"
          [id]="article.id"
          [link]="article.attributes.url"
        ></app-article-list-item>
      </div>
    </div>

    <ng-container class="no-data">
      <shared-no-data [title]="'articles.noData' | trans" icon="fas fa-newspaper" size="large"></shared-no-data>
    </ng-container>
  </cms-content>
</div>
