import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@app/common/services/api.service';
import { API } from '@app/common/constants/api.directories';

@Injectable({providedIn: 'root'})
export class ContactService {

  constructor(private apiService: ApiService) {
  }

  public submit(contact: any): Observable<any> {
    return this.apiService.post('contact', contact);
  }
}
