import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Preview } from '@app/client-core/preview/interfaces/preview.interface';
import { ResponseObject } from '@share/common/models/http';
import { PageModel } from '@app/common/models/page.model';
import { ApiService } from '@app/common/services/api.service';
import { API } from '../constants/api.directories';

@Injectable({
  providedIn: 'root'
})
export class PagesService implements Preview {
  constructor(private apiService: ApiService) {}

  getOne(slug: string = ''): Observable<ResponseObject<PageModel<any>>> {
    return this.apiService.get(`${API.PAGES.ROOT}${slug ? '/' + slug : ''}`);
  }
}
