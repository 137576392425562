import { Injectable } from '@angular/core';

declare var $: Function;

@Injectable({
  providedIn: 'root'
})
export class ScrollToService {

  constructor() {}

  animate(id: string, time: number = 500, scrollDifference: number = 70) {
    try {
      $('html, body').animate({
        scrollTop: ($(id).offset().top as number) - scrollDifference,
      }, time);
    } catch (ignore) {
    }
  }

  resetScrollTop() {
    window.scrollTo(0, 0);
  }
}

