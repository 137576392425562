import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementsModule } from '@share/modules/elements/elements.module';
import { LogoModule } from '@app/template/layout/modules/logo/logo.module';
import { RouterModule } from '@angular/router';
import { SitemapComponent } from '@app/template/layout/modules/sitemap/components/sitemap/sitemap.component';
import { SitemapItemComponent } from '@app/template/layout/modules/sitemap/components/sitemap/sitemap-item/sitemap-item.component';
import { TranslatorModule } from '@share/modules/translator/translator.module';

@NgModule({
  declarations: [
    SitemapComponent,
    SitemapItemComponent,
  ],
    imports: [
        CommonModule,
        ElementsModule,
        LogoModule,
        RouterModule,
        TranslatorModule,
    ],
  exports: [
    SitemapComponent
  ]
})
export class SiteMapModule {
}

