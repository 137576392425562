import { Injectable } from '@angular/core';
import { API } from '@app/common/constants/api.directories';
import { COMPONENTS } from '@app/client-core/dynamic-content/constants/component-list';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { MetaResponse, ResponseDefault } from '@share/common/models/http.model';
import { Nullable } from '@share/common/types/utilities.types';
import { ComponentInterface } from '@app/client-core/dynamic-content/interfaces/components.interface';
import { distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '@app/common/services/api.service';
import { NavigationEnd, Router } from '@angular/router';
import { DynamicUtilsService } from '@app/client-core/dynamic-content/service/dynamic-utils.service';
import { ElementService } from '@app/client-core/dynamic-content/service/element.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicContentService {
  private _loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  loaded$: Observable<boolean> = this._loaded.asObservable().pipe(distinctUntilChanged());
  homeComponent: Nullable<ComponentInterface>;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dynamicUtilsService: DynamicUtilsService,
    private elementService: ElementService
  ) {}

  set loaded(value: boolean) {
    this._loaded.next(value);
  }

  get runtimeUrlReader$(): Observable<string> {
    return this.router.events.pipe(
      startWith(new NavigationEnd(0, this.router.url, this.router.url)),
      filter((e: any): e is NavigationEnd => e instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)
    );
  }

  componentLoader(rawUrl: string): Observable<Nullable<ComponentInterface>> {
    return of(rawUrl).pipe(
      map((rawUrl: string) => this.dynamicUtilsService.parseUrlToBeProcessable(rawUrl)),
      switchMap((url: string) => this.getPageMetadata(url)),
      tap(response => {
        if (response?.meta?.objectId) {
          this.elementService.objectId = response?.meta?.objectId
        }
      }),
      map((response: any) => response.meta),
      switchMap((metadata: MetaResponse) => this.getComponentByType(metadata?.type))
    );
  }

  private getComponentByType(type: string | undefined): Observable<Nullable<ComponentInterface>> {
    if (type) {
      const component: Nullable<ComponentInterface> = this.getComponent(type);
      if (!component) {
        return throwError(`Not found component ${type}`);
      }
      return of(component);
    } else {
      return throwError(`Component's type is not provided: ${type}`);
    }
  }

  getPageMetadata(url: string): Observable<ResponseDefault> {
    return this.apiService.get(`${API.DYNAMIC_CONTENT.ROOT}${url && url != ' ' ? '/' + url : ''}`);
  }

  private getComponent(module: string): Nullable<ComponentInterface> {
    try {
      return COMPONENTS[module.toLowerCase()];
    } catch (e) {
      return null;
    }
  }
}
