import { Component, OnInit } from '@angular/core';
import { GoogleTranslateService } from '@app/common/services/google-translate.service';

@Component({
  selector: 'app-google-translator',
  templateUrl: './google-translator.component.html',
  styleUrls: [
    './google-translator.component.scss'
  ]
})
export class GoogleTranslatorComponent implements OnInit {

  constructor(private googleTranslateService: GoogleTranslateService) {}

  public ngOnInit() {
    this.googleTranslateService.init();
  }

}
