import {Injectable} from '@angular/core';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  constructor(private location: Location) {}

  getParsedUrl(): string {
    const path: string = this.location.path();
    return path.substr(1, path.length).replace(/\//g, ',');
  }

  getLastUrlElement(): string {
    const paths: string[] = this.getParsedUrl().split(',');
    return paths[paths.length - 1];
  }
}
