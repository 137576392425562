import { Component, OnInit } from '@angular/core';
import { InstitutionService } from '@app/common/services/institution.service';
import { AbstractData } from '@share/common/models/http';
import { first } from 'rxjs/operators';
import { Institution } from '@app/common/models/institution.model';

@Component({
  selector: 'app-contact-subheader',
  templateUrl: 'contact-subheader.component.html',
  styleUrls: ['contact-subheader.component.scss']
})
export class ContactSubheaderComponent implements OnInit {

  institution: AbstractData<Institution>;

  constructor(private institutionService: InstitutionService) {}

  ngOnInit() {
    this.getInstitutionData();
  }

  getInstitutionData() {
    this.institutionService
      .getCurrent()
      .pipe(
        first()
      )
      .subscribe(response => this.institution = response.data)
  }

}
