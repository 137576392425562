import { Validators } from '@angular/forms';

export function getContactFormGroup(): { [id: string]: any } {
  return {
    senderName: ['', Validators.required],
    senderEmail: ['', Validators.required],
    content: ['', Validators.required],
    dataProcessConsent: [false, Validators.requiredTrue],
    captcha: ['', Validators.required]
  };
}
