<div id="error-pages">
  <div class="error-content">
    <div class="error-logo"></div>
    <div class="error-content">
      <h1>{{'global.maintenance' | trans}}</h1>
      <p>{{'global.maintenanceText' | trans}}.</p>
    </div>
  </div>
</div>

