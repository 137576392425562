import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';

@Injectable()
export class SearchHelperService {
  amount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  keyword: BehaviorSubject<string> = new BehaviorSubject<string>('');

  getData(): Observable<{ amount: number; keyword: string }> {
    return forkJoin({ amount: this.amount, keyword: this.keyword });
  }
}
