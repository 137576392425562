<ul>
  <li class="normal">
    <button class="btn" (click)="removeContrast()">
      <span class="sr-only">brak wysokiego kontrastu</span>
      <span aria-hidden="true">A</span>
    </button>
  </li>
  <li class="blackwhite">
    <button class="btn" (click)="addBlackWhite()">
      <span class="sr-only">czarno-biały</span>
      <span aria-hidden="true">A</span>
    </button>
  </li>
  <li class="yellowblack">
    <button class="btn" (click)="addYellowBlack()">
      <span class="sr-only">żółto-czarny</span>
      <span aria-hidden="true">A</span>
    </button>
  </li>
</ul>
