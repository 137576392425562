import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() {
  }

  public getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  public setCookie(name: string, value: string, expireDays: number = 0): void {
    let cookie = '';
    cookie += `${name}=${value};`;
    const d: Date = new Date();
    if (expireDays !== 0) {
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);

      cookie += `expires=${d.toString()}`;
    }
    document.cookie = cookie;

  }

}
