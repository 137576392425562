import {BootstrapService} from '@app/common/services/bootstrap.service';
import { LanguageService } from '@share/common/services/language.service';

export function getLanguageFactory(language: LanguageService): () => string {
  return () => language.getLanguage();
}

export function getTranslationsFactory(service: BootstrapService): () => Promise<any> {
  return () => service.getTranslations();
}

export function getMaintenanceFactory(service: BootstrapService): () => Promise<any> {
  return () => service.checkMaintenance();
}

export function getSettingsFactory(service: BootstrapService): () => Promise<any> {
  return () => service.loadSettings();
}

export function getBackendVersionFactory(service: BootstrapService): () => Promise<any> {
  return () => service.getBackendVersion();
}

export function getFrontendVersionFactory(service: BootstrapService): () => Promise<any> {
  return () => service.getFrontendVersion();
}

export function getPermissions(service: BootstrapService): () => Promise<any> {
  return () => service.getPermissions();
}
