<div class="default-app-overlay" [class.backdrop]="backdrop$ | async">
  <div class="layout-default" >
    <app-header *ngIf="canShow | async"></app-header>
    <app-subheader></app-subheader>
    <main class="container-fluid">
      <router-outlet (deactivate)="deactivate()"></router-outlet>
    </main>
    <app-cookie-information-bar></app-cookie-information-bar>
  </div>
  <app-sitemap *ngIf="contentLoaded | async"></app-sitemap>
  <app-footer *ngIf="contentLoaded | async"></app-footer>
</div>
