import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionComponent } from './component/accordion/accordion.component';
import { AccPanelTitleDirective, AccPanelContentDirective, AccPanelDirective } from './directives/accordion-panel.directive';

@NgModule({
  imports: [CommonModule],
  exports: [
    AccordionComponent,
    AccPanelTitleDirective,
    AccPanelContentDirective,
    AccPanelDirective
  ],
  declarations: [
    AccordionComponent,
    AccPanelTitleDirective,
    AccPanelContentDirective,
    AccPanelDirective
  ]
})
export class AccordionModule {}
