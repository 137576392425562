<section id="promoted">
  <div class="container">
    <shared-heading-section
      [title]="data?.title"
      [subtitle]="data?.subtitle"
    ></shared-heading-section>
    <cms-content
      [loading]="loading"
      [value]="promoted"
      [error]="componentError"
      (componentRefreshed)="loadPromoted()"
    >
      <app-promoted-list [data]="promoted"></app-promoted-list>
    </cms-content>
  </div>
</section>
