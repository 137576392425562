<div
  class="department"
  [class.no-image]="
    !((data.smallImage.url || data.smallImage.croppedUrl) && (data.bigImage.url || data.bigImage.croppedUrl))
  "
  [class.special]="special"
  [class.specialTwo]="specialTwo"
>
  <div class="overlay" [class.right]="rightImage">
    <div
      class="layer image"
      *ngIf="(data.smallImage.url || data.smallImage.croppedUrl) && (data.bigImage.url || data.bigImage.url)"
    >
      <app-contact-images
        [smallerImageUrl]="data.smallImage?.url || data.smallImage?.croppedUrl"
        [biggerImageUrl]="data.bigImage?.url || data.bigImage?.url"
        [isRight]="rightImage"
        [special]="specialTwo"
        [smallUp]="special"
      ></app-contact-images>
    </div>
    <div class="layer title text">
      <h3>{{ data.title }}</h3>
    </div>
    <div class="layer text" *ngFor="let person of data.people">
      <ng-container
        [ngTemplateOutlet]="personOrPlaceTemplate"
        [ngTemplateOutletContext]="{ $implicit: person }"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template #personOrPlaceTemplate let-person>
  <div class="personOrPlace">
    <p class="description" *ngIf="person?.description">{{person.description}}</p>
    <p><span class="fullName" *ngIf="person.fullName">{{person.fullName}}</span><span *ngIf="person?.scope"> - {{person?.scope}}</span></p>
    <p *ngFor="let contact of person.contacts" class="contacts">
      <ng-container *ngIf="contact.type === 'phone'">
        <span class="sr-only">Numer telefonu</span>
        <i class="fas fa-phone"></i>
        <a [href]="'tel:' + contact.value">{{ contact.value }}</a>
        <span class="extra" *ngIf="contact.extra"> wew. {{ contact.extra }}</span>
      </ng-container>

      <ng-container *ngIf="contact.type === 'email'">
        <span class="sr-only">Email</span>
        <i class="fas fa-envelope"></i>
        <a [href]="'mailto:' + contact.value">{{ contact.value }}</a>
      </ng-container>
    </p>
  </div>
</ng-template>
