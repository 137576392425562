<div class="app-accordion">
  <ng-template ngFor let-panel [ngForOf]="panels" [ngForTrackBy]="identify">
    <div class="card" [class.disabled]="panel.disabled" [class.active]="isPanelActive(panel.id)">
      <div class="card-header"
           id="{{panel.id}}-header"
           tabindex="0"
           (click)="toggle(panel.id, $event.target)"
           (keyup.enter)="toggle(panel.id, $event.target)"
      >
        <ng-template [ngTemplateOutlet]="panel.titleTpl?.templateRef"></ng-template>
      </div>
      <div *ngIf="panel.isOpen" class="card-body" id="{{panel.id}}-body">
        <ng-template [ngTemplateOutlet]="panel.contentTpl?.templateRef"></ng-template>
      </div>
    </div>
  </ng-template>
</div>
