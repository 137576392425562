import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from '@app/common/services/settings.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  url: string;

  @Input() id: string = 'logo';
  @Input() isAlt: boolean;
  @Input() preventChange: boolean;

  constructor(private layoutService: SettingsService) {}

  ngOnInit() {
    if (this.isAlt) {
      this.url = this.layoutService.variables.site.altLogo;
    } else {
      this.url = this.layoutService.variables.site.logo;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
