import { Injectable, Injector } from '@angular/core';
import { FacadeShareService } from '@share/common/services/facade-share.service';
import { SubheaderService } from '@app/template/layout/modules/header/services/subheader.service';
import { ScrollToService } from '@app/common/services/scroll-to.service';
import { SettingsService } from '@app/common/services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class FacadeService extends FacadeShareService {
  private _subheader: SubheaderService;
  private _scrollTo: ScrollToService;
  private _settings: SettingsService<any>;

  constructor(private injector: Injector) {
    super(injector);
  }

  public get subheader(): SubheaderService {
    if (!this._subheader) {
      this._subheader = this._injector.get(SubheaderService);
    }
    return this._subheader;
  }

  public get scrollTo(): ScrollToService {
    if (!this._scrollTo) {
      this._scrollTo = this._injector.get(ScrollToService);
    }
    return this._scrollTo;
  }

  public get settings(): SettingsService<any> {
    if (!this._settings) {
      this._settings = this._injector.get(SettingsService);
    }
    return this._settings;
  }
}
