import { Component, OnInit } from '@angular/core';
import { ComponentHelper } from '@app/common/helpers/component.helper';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent extends ComponentHelper implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

  goToHome(): void {
    this.service.router.navigate(['']);
  }

}
