import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiCarouselComponent } from './components/multi-carousel/multi-carousel.component';
import { TranslatorModule } from '@share/modules/translator/translator.module';
import { PolygonModule } from '@app/template/elements/polygon/polygon.module';

@NgModule({
  declarations: [
    MultiCarouselComponent
  ],
  imports: [
    CommonModule,
    TranslatorModule,
    PolygonModule
  ],
  exports: [
    MultiCarouselComponent
  ]
})
export class CarouselModule {}
