import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { AbstractData } from '@share/common/models/http';
import { MenuModel } from '@app/client-core/menu/models/menu.model';
import { MenuService } from '@app/client-core/menu/services/menu.service';
import { FacadeService } from '@app/common/services/facade.service';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-mobile-menu',
  templateUrl: 'mobile-menu.component.html',
  styleUrls: ['mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileMenuComponent implements OnDestroy, AfterViewInit {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() menu: AbstractData<MenuModel>[];

  @ViewChild('sidebarMobile') sidebarMobile: ElementRef;

  getError: boolean;
  getLoading: boolean;
  open: boolean;
  shownComplete: boolean;

  constructor(private menuService: MenuService,
              private facadeService: FacadeService,
              private cdr: ChangeDetectorRef,
              private _elementRef: ElementRef) {}

  ngOnInit() {
    this.shownComplete = true;
    this.listenForServiceChange();
    this.handleOutsideClick();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  listenForServiceChange() {
    this.menuService.isMobileMenuActive$.pipe(takeUntil(this.destroy$)).subscribe(isActive => {
      if (!isActive) {
        this.hide();
      }
    });
  }

  hide() {
    this.open = false;
    $('.navbar-collapse').collapse('hide');
    this.cdr.detectChanges();
  }

  toggleMenu() {
    if (this.shownComplete) {
      this.shownComplete = false;
      this.open = !this.open;
    }
  }

  ngAfterViewInit() {
    $('.navbar-collapse')
      .on('shown.bs.collapse', () => (this.shownComplete = true))
      .on('hidden.bs.collapse', () => (this.shownComplete = true))
  }

  setLoader(value: boolean) {
    this.getLoading = value;
    this.cdr.detectChanges();
  }

  parseId(id: string): string {
    return id.replace(/ /g,'')
  }

  handleOutsideClick() {
    const clickEvent$ = fromEvent(document, 'click');
    clickEvent$
      .pipe(
        takeUntil(this.destroy$),
        filter(e => !this._elementRef.nativeElement.contains(e.target)),
        tap(() => this.hide())
      )
      .subscribe();
  }
}
