import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { SettingsService } from '@app/common/services/settings.service';

declare const grecaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class CaptchaV3Service {
  public readonly grecaptcha: Subject<any> = new ReplaySubject<any>();
  private scriptElement: HTMLScriptElement;

  constructor(private settingsService: SettingsService) {}

  public load(): void {
    if (this.scriptElement) {
      return;
    }
    this.scriptElement = document.createElement('script');
    this.scriptElement.src = `https://www.google.com/recaptcha/api.js?render=${this.settingsService.variables.integration.googleReCaptchaSiteKey}`;
    this.scriptElement.async = true;
    this.scriptElement.addEventListener('load', event => {
      grecaptcha.ready(() => {
        this.grecaptcha.next(grecaptcha);
      });
    });
    this.scriptElement.addEventListener('error', event => {
      this.grecaptcha.next(null);
    });
    document.getElementsByTagName('head')[0].append(this.scriptElement);

    this.toggleVisibility();
  }

  execute(action: string = 'homepage'): Observable<any> {
    return this.grecaptcha.pipe(first()).pipe(
      switchMap(val => {
        if (val === null) {
          throw new Error('Google captcha script is not loaded');
        }
        return val.execute(this.settingsService.variables.integration.googleReCaptchaSiteKey, { action });
      })
    );
  }

  toggleVisibility(show: boolean = true) {
    const grecaptcha: HTMLElement | null = document.querySelector('.grecaptcha-badge');
    if (grecaptcha) {
      grecaptcha.style.display = show ? 'block' : 'none';
    }
  }
}
