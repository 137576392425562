import { OnDestroy, Type } from '@angular/core';
import { ComponentShareHelper } from '@share/common/helpers/component-share.helper';
import { FacadeShareService } from '@share/common/services/facade-share.service';
import { ServiceLocator } from '@share/common/services/locater.service';
import { FacadeService } from '../services/facade.service';

export abstract class ComponentHelper extends ComponentShareHelper implements OnDestroy {

  protected service: FacadeService;

  protected constructor() {
    super(ServiceLocator.injector.get<FacadeShareService>(FacadeShareService as Type<FacadeShareService>));
    this.service = ServiceLocator.injector.get<FacadeService>(FacadeService as Type<FacadeService>);
  }
}
