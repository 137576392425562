<div class="modal-header">
  <h4 class="modal-title">{{data.alt}}</h4>
  <button type="button" class="close" aria-label="Close" tabindex="0"
          (click)="close()"
          (keyup.enter)="close()"
  >
    <span aria-hidden="true">{{'x'}}</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="loading">
    <cms-progress-bar></cms-progress-bar>
  </ng-container>
  <img *ngIf="!placeholder" [src]="data.original" [alt]="data.alt" (load)="onLoad()" (error)="onError()"/>
  <ng-container *ngIf="placeholder">
    <img class="d-block mx-auto" src="/assets/source/img/no-image.svg" [alt]="i18n.global.noData"/>
  </ng-container>
</div>
