import { ReplaySubject } from 'rxjs';
import { Injector } from '@angular/core';

export interface SubheaderConfig {
  title: string;
  description: string;
  displayHeader: boolean;
  displayBreadcrumbs: ReplaySubject<boolean>;
  searchOpen: boolean;
  searchType: SearchType;
  component: any;
  categories?: { slug: string; title: string }[];
  hideSearch: boolean;
  injector?: Injector | null;
  forceShow: boolean;
  publishedDate?: string;
  image?: string;
  hideDots?: boolean
}

export enum SearchType {
  Default = '',
  EServices = 'eservice',
  None = ''
}

export enum SearchTypeNames {
  Default = 'Wszędzie',
  EServices = 'Katalog e-usług'
}
