<ng-container *ngIf="loading">
  <div class="loading-bar-content">
    <div class="loading-bar"></div>
  </div>
</ng-container>

<ng-container *ngIf="data?.attributes?.content as content">
  <app-main-banner [data]="content.content.sectionOne"></app-main-banner>
  <app-report-crash [data]="content.content.sectionTwo"></app-report-crash>
  <app-promoted [data]="content.content.sectionThree"></app-promoted>
  <app-schedule [data]="content.content.sectionFour"></app-schedule>
  <app-news [data]="content.content.sectionFive"></app-news>
  <app-gallery [data]="content.content.sectionSix"></app-gallery>
  <app-simple-box></app-simple-box>
</ng-container>
