import {ComponentHelper} from '@app/common/helpers/component.helper';
import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent extends ComponentHelper {

  public language: string = 'pl';

  constructor() {
    super();
  }

  changeLanguage(): void {
    alert('TODO');
  }
}
