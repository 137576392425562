import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './components/default/default.component';
import { HeaderModule } from '@app/template/layout/modules/header/header.module';
import { FooterModule } from '@app/template/layout/modules/footer/footer.module';
import { CookiesModule } from '@app/client-core/cookies/cookies.module';
import { DynamicContentModule } from '@app/client-core/dynamic-content/dynamic-content.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { HomeModule } from '@app/template/home/home.module';
import { SiteMapModule } from '@app/template/layout/modules/sitemap/sitemap.module';
import { ElementsModule } from '@share/modules/elements/elements.module';
import { PolygonModule } from '@app/template/elements/polygon/polygon.module';

@NgModule({
  declarations: [DefaultComponent],
  imports: [
    CommonModule,
    HeaderModule,
    HomeModule,
    FooterModule,
    CookiesModule,
    DynamicContentModule,
    SiteMapModule,
    AppRoutingModule,
    ElementsModule,
    PolygonModule
  ]
})
export class LayoutModule {}
