import { APP_INITIALIZER, LOCALE_ID, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BootstrapService } from '@app/common/services/bootstrap.service';
import {
  getBackendVersionFactory,
  getFrontendVersionFactory,
  getLanguageFactory,
  getMaintenanceFactory,
  getPermissions,
  getSettingsFactory,
  getTranslationsFactory
} from '@app/common/functions/bootstrap';
import { LanguageService } from '@share/common/services/language.service';
import { InstitutionInterceptorService } from '@app/common/interceptors/institution-interceptor.service';
import { ContentInterceptorService } from '@app/common/interceptors/content-interceptor.service';
import { ApiInterceptorService } from '@app/common/interceptors/api-interceptor.service';

export const BOOTSTRAP_PROVIDERS: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ContentInterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: InstitutionInterceptorService,
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [LanguageService],
    useFactory: getLanguageFactory
  },
  {
    provide: APP_INITIALIZER,
    useFactory: getTranslationsFactory,
    deps: [BootstrapService],
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: getMaintenanceFactory,
    deps: [BootstrapService],
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: getSettingsFactory,
    deps: [BootstrapService],
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: getBackendVersionFactory,
    deps: [BootstrapService],
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: getFrontendVersionFactory,
    deps: [BootstrapService],
    multi: true
  }
];
