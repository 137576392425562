<div class="row">
  <div id="error-pages">
    <div class="error-content">
      <div class="error-content">
        <h1>{{ '500' }}</h1>
        <p>{{ 'Błąd serwera' }}</p>
        <cms-button
          [text]="'Odśwież'"
          (clicked)="goToHome()"
          [icon]="buttonIcons.RedoAlt"
          [btnClass]="[buttonClasses.Primary]"
          [type]="buttonTypes.Button"
        ></cms-button>
      </div>
    </div>
  </div>
</div>
