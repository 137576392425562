<div class="box" [style.background-image]="'url(' + (data.file?.croppedUrl || data?.file?.url)  +')'">
  <div class="content">
    <div class="title" [innerHTML]="data?.title | safe: 'html'"></div>
    <div class="subtitle" [innerHTML]="data?.content | safe: 'html'"></div>
    <cms-button
      [id]="'btn-get-more'"
      (clicked)="scrollDown()"
      [text]="data?.button"
      [btnClass]="['btn-secondary reverse-icon']"
      [icon]="'fas fa-arrow-down'"
    ></cms-button>
  </div>
</div>
