import { NgModuleFactory, Type } from '@angular/core';
import { ThemesEnum } from '@app/common/models/themes.enum';

export const lazyWidgets: { name: string; loadChildren: () => Promise<NgModuleFactory<any> | Type<any>> }[] = [
  {
    name: ThemesEnum.CORE,
    loadChildren: () => import('@app/client-core/static-pages/static-pages.module').then(m => m.StaticPagesModule)
  },
  {
    name: ThemesEnum.ARTICLE,
    loadChildren: () => import('@app/client-core/article/article.module').then(m => m.ArticleModule)
  },
  {
    name: ThemesEnum.MENU,
    loadChildren: () => import('@app/client-core/menu/menu.module').then(m => m.MenuModule)
  }
];

export function lazyArrayToObj() {
  const result = {};
  for (const w of lazyWidgets) {
    result[w.name] = w.loadChildren;
  }
  return result;
}
