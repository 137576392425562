import { Component } from '@angular/core';
import { ComponentHelper } from '@app/common/helpers/component.helper';
import { BreadcrumbsService } from '@app/template/layout/modules/header/services/breadcrumbs.service';
import { SubheaderService } from '@app/template/layout/modules/header/services/subheader.service';
import { Observable, of } from 'rxjs';
import { DynamicContentService } from '@app/client-core/dynamic-content/service/dynamic-content.service';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import { SettingsService } from '@app/common/services/settings.service';
import { SearchService } from '@app/client-core/search/services/search.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent extends ComponentHelper {
  isHome: boolean = true;
  backdrop$: Observable<boolean>;
  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private subheaderService: SubheaderService,
    private settings: SettingsService,
    private dynamicContentService: DynamicContentService,
    private searchService: SearchService
  ) {
    super();
    this.setHome();
    this.backdrop$ = searchService.isActive$;
  }

  get contentLoaded(): Observable<boolean> {
    return this.dynamicContentService.loaded$;
  }

  public deactivate() {
    this.breadcrumbsService.clear.next(true);
    this.subheaderService.reset();
  }

  private setHome() {
    this.service.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe(event => {
        this.isHome = event.url === '/';
      });
  }

  get canShow(): Observable<boolean> {
    if (this.isHome) {
      return this.contentLoaded;
    }
    return of(true);
  }
}
