<nav id="mainNav" aria-label="breadcrumb" *ngIf="breadcrumbsExists; else default" [ngClass]="{'mobile': isMobile}">
  <ol class="breadcrumb">
    <ng-container *ngIf="!shortVersion">
      <ng-container *ngFor="let breadcrumb of breadcrumbs;trackBy:identify; let i = index, let l = last">
        <li class="breadcrumb-item" *ngIf="!l; else activeBreadcrumb">
          <a *ngIf="breadcrumb.attributes.isLink" [routerLink]="getLink(i)">{{breadcrumb.attributes.title}}</a>
          <span *ngIf="!breadcrumb.attributes.isLink">{{breadcrumb.attributes.title}}</span>
        </li>
        <ng-template #activeBreadcrumb>
          <li class="breadcrumb-item active" aria-current="page">{{breadcrumb.attributes.title}}</li>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="shortVersion">
      <li class="breadcrumb-item">
        <a *ngIf="breadcrumbs[0].attributes.isLink" [routerLink]="getLink(0)">{{breadcrumbs[0].attributes.title}}</a>
        <span *ngIf="!breadcrumbs[0].attributes.isLink">{{breadcrumbs[0].attributes.title}}</span>
      </li>
      <li class="breadcrumb-item">
        <a href="#" tabindex="0"
           (click)="expandBreadcrumbs($event)"
           (keyup.enter)="expandBreadcrumbs($event)"
        >{{'...'}}</a>
      </li>
      <li class="breadcrumb-item active"
          aria-current="page">{{breadcrumbs[breadcrumbs.length - 1].attributes.title}}</li>
    </ng-container>
  </ol>
</nav>
<ng-template #default>
  <ng-container *ngIf="customBreadcrumbs.length > 0">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <ng-container *ngFor="let customBreadcrumb of customBreadcrumbs;trackBy:identify;let l = last">
          <li class="breadcrumb-item" *ngIf="!l; else activeBreadcrumb">
            <a [routerLink]="customBreadcrumb.url">{{customBreadcrumb.title}}</a>
          </li>
          <ng-template #activeBreadcrumb>
            <li class="breadcrumb-item active" aria-current="page">{{customBreadcrumb.title}}</li>
          </ng-template>
        </ng-container>
      </ol>
    </nav>
  </ng-container>
</ng-template>
