import { NgModule } from '@angular/core';
import { SimpleBoxComponent } from '@app/template/elements/simple-box/components/simple-box.component';
import { ElementsModule } from '@share/modules/elements/elements.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SimpleBoxComponent],
    imports: [
        ElementsModule,
        CommonModule
    ],
  exports: [SimpleBoxComponent]
})
export class SimpleBoxModule {

}
