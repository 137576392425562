import {Injectable} from '@angular/core';
import {ServiceHelper} from '@share/common/helpers/service.helper';
import {Bundle} from '@app/common/enums/server-bundles.enum';
import {Observable} from 'rxjs';
import {MenuModel} from '@app/client-core/menu/models/menu.model';
import {ResponseArray} from '@share/common/models/http';
import {API} from '@app/common/constants/api.directories';
import { ApiService } from '@app/common/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SitemapService {

  constructor(private apiService: ApiService) {}

  getInstitutionData(): Observable<any> {
    return this.apiService.get('institution');
  }

  public getList(): Observable<ResponseArray<MenuModel>> {
    return this.apiService.getAll<MenuModel>(API.MENU.SITEMAP);
  }
}
