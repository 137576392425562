<div class="layer">
  <svg viewBox="0 0 1440 422" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-3 17.9797C-3 17.9797 408.5 0.5 718 0.5C1027.5 0.5 1440 17.9797 1440 17.9797V421.5H-3V17.9797Z"/>
  </svg>
</div>
<section id="sitemap">
  <div class="container">
    <div class="row">
      <div class="col-lg-custom col-12 pt-5 pb-5">
          <div class="d-flex align-items-center">
            <img class="app-logo" [src]="institution?.attributes?.logo" [alt]="'global.institutionLogo' | trans" />
            <p class="institution-name">{{institution?.attributes?.name}}</p>
          </div>
        <div class="contact">
          <div class="street">
            <span>{{ institution?.attributes?.address?.zipCode }} {{ institution?.attributes?.address?.city }}</span>
            <span
              >ul. {{ institution?.attributes?.address?.street }} {{ institution?.attributes?.address?.houseNumber }}
              {{
                institution?.attributes?.address?.apartmentNumber
                  ? '/' + institution?.attributes?.address?.apartmentNumber
                  : ''
              }}
            </span>
          </div>
          <div class="contact-info">
            <a *ngIf="institution?.attributes?.contact?.phoneNumber" [href]="'tel:' + institution?.attributes?.contact?.phoneNumber">
              <i class="fas fa-phone"></i>
              {{ institution?.attributes?.contact?.phoneNumber }}
            </a>
            <a *ngIf="institution?.attributes?.contact?.phoneNumberSecondary" [href]="'tel:' + institution?.attributes?.contact?.phoneNumberSecondary">
              <i class="fas fa-phone"></i>
              {{ institution?.attributes?.contact?.phoneNumberSecondary }}
            </a>
            <a *ngIf="institution?.attributes?.contact?.fax" [href]="'fax:' + institution?.attributes?.contact?.fax">
              <i class="fas fa-fax"></i>
              {{ institution?.attributes?.contact?.fax }}
            </a>
            <a *ngIf="institution?.attributes?.contact?.email" [href]="'mailto:' + institution?.attributes?.contact?.email">
              <i class="fas fa-envelope"></i>
              {{ institution?.attributes?.contact?.email }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg col-12">
        <cms-content
          [value]="sitemap"
          [error]="componentError"
          [loading]="loaders[SitemapLoaders.GetAll]"
          (componentRefreshed)="loadSitemap()"
        >
          <div class="row sitemap-list">
            <ng-container *ngFor="let item of sitemap; trackBy: identify">
              <div class="col-md-4 sitemap-item" *ngIf="item.attributes.children.length > 0">
                <app-sitemap-item [item]="item"></app-sitemap-item>
              </div>
            </ng-container>
          </div>
        </cms-content>
      </div>
    </div>
  </div>
</section>
