import { Injectable } from '@angular/core';
import { API } from '@app/common/constants/api.directories';
import { Article } from '@app/client-core/article/models/article.model';
import { ResponseArray, ResponseObject } from '@share/common/models/http';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ElementService } from '@app/client-core/dynamic-content/service/element.service';
import { Preview } from '@app/client-core/preview/interfaces/preview.interface';
import { first } from 'rxjs/operators';
import { ApiService } from '@app/common/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService implements Preview {
  constructor(protected element: ElementService, private apiService: ApiService) {}

  getList(
    page: number,
    count: number,
    category?: string | null,
    slug?: string | null,
  ): Observable<ResponseArray<Article>> {
    let params: HttpParams = new HttpParams().append('page', page.toString()).append('count', count.toString());
    if (category) {
      params = params.set('category', category);
    } else if (slug) {
      params = params.append('categorySlug', slug);
    } else if (this.element.objectId) {
      params = params.set('category', this.element.objectId);
    }
    return this.apiService.getAll<Article>(`${API.ARTICLES.ROOT}`, params);
  }


  getRandomArticles(expect: string, count: number = 3) {
    let params: HttpParams = new HttpParams()
      .append('limit', count.toString())
      .append('except', expect);
    return this.apiService.getAll<Article>(`${API.ARTICLES.RANDOM}`, params);
  }

  getOne(slug: string): Observable<ResponseObject<Article>> {
    return this.apiService.get<Article>(`${API.ARTICLES.ROOT}/${slug}`).pipe(first());
  }
}
