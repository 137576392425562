import {HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {Injectable, Provider} from '@angular/core';
import * as Hammer from 'hammerjs';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    return new Hammer(element, {
      touchAction: 'pan-y pan-x'
    });
  }
}

export const HAMMER_PROVIDER: Provider[] = [
  {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }
];
