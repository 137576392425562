import { Component, Input } from '@angular/core';
import { FacadeService } from '@app/common/services/facade.service';
import { ContactSubheaderComponent } from '@app/client-core/static-pages/components/static-page-contact/contact-subheader/contact-subheader.component';

@Component({
  selector: 'app-static-page-contact',
  templateUrl: './static-page-contact.component.html',
  styleUrls: ['./static-page-contact.component.scss']
})
export class StaticPageContactComponent {
  @Input() data: {
    sectionOne: any,
    sectionTwo: any,
    sectionThree: any,
    sectionFour: any
  };

  constructor(private facadeService: FacadeService) {
    this.facadeService.subheader.setConfig({
      component: ContactSubheaderComponent,
      hideDots: true
    });
    this.facadeService.subheader.displayBreadcrumbs = false;
  }
}
