<div *ngIf="dataExists" class="row">
  <ng-container *ngFor="let promoted of data | slice: 0:3; trackBy: identify">
    <div class="col-lg-4 col-12">
      <a
        (click)="onClick(promoted.id)"
        (keyup.enter)="onClick(promoted.id)"
        *ngIf="!promoted.attributes.openInNewTab"
        [attr.href]="promoted.attributes.url"
      >
        <ng-container
          *ngTemplateOutlet="promotedContent; context: { $implicit: promoted, height: height }"
        ></ng-container>
      </a>
      <a
        (click)="navigateTo($event, promoted.attributes.url, promoted.id)"
        (keyup.enter)="navigateTo($event, promoted.attributes.url, promoted.id)"
        *ngIf="promoted.attributes.openInNewTab"
        tabindex="0"
      >
        <ng-container
          *ngTemplateOutlet="promotedContent; context: { $implicit: promoted, height: height }"
        ></ng-container>
      </a>
    </div>
  </ng-container>
  <ng-template #promotedContent let-height="height" let-promoted>
    <div class="promoted">
      <app-promoted-item-icon [icon]="promoted.attributes.icon || 'other-oil'"></app-promoted-item-icon>
      <h3>{{ promoted.attributes.title | truncate: 50 }}</h3>
      <div class="promoted-description" [innerHTML]="promoted.attributes.shortDescription | safe: 'html'"></div>
      <span aria-hidden="true" class="readmore">{{'global.findOutMore' | trans}}</span>
    </div>
  </ng-template>
</div>
