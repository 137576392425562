import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-contact-images',
  templateUrl: 'contact-images.component.html',
  styleUrls: ['contact-images.component.scss']
})
export class ContactImagesComponent {
  @Input() biggerImageUrl: string;
  @Input() smallerImageUrl: string;

  @Input() isRight: boolean;
  @Input() special: boolean;
  @Input() smallUp: boolean;
}
