import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTES_PATHS } from '@app/common/constants/routes-paths';
import { DynamicContentComponent } from '@app/client-core/dynamic-content/component/dynamic-content/dynamic-content.component';
import { NotFoundComponent } from '@app/template/layout/modules/error-pages/components/not-found/not-found.component';
import { MaintenanceComponent } from '@app/template/layout/modules/error-pages/components/maintenance/maintenance.component';
import { FatalErrorComponent } from '@app/template/layout/modules/error-pages/components/fatal-error/fatal-error.component';
import { SearchResultsComponent } from '@app/client-core/search/components/search-results/search-results.component';
import { DefaultComponent } from '@app/template/layout/components/default/default.component';

export const routes: Routes = [
  {
    path: ROUTES_PATHS.maintenance.root,
    component: MaintenanceComponent
  },
  {
    path: ROUTES_PATHS.unknownErrorPage.root,
    component: FatalErrorComponent
  },
  {
    path: '',
    component: DefaultComponent,
    children: [
      {
        path: 'preview',
        loadChildren: () => import('@app/client-core/preview/preview.module').then(m => m.PreviewModule)
      },
      {
        path: 'search/:data',
        component: SearchResultsComponent
      },
      {
        path: ROUTES_PATHS.notFoundPage.root,
        component: NotFoundComponent
      },
      {
        path: '**',
        component: DynamicContentComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
