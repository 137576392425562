<app-container>
  <div class="container">
    <cms-content [loading]="loading" [error]="getError" [value]="article" (componentRefreshed)="loadArticle()">
      <div class="article-item-content">
        <div class="content-view-box clearfix" *ngIf="article">
          <div class="content-view-body">
            <div
              *ngIf="article.attributes.content"
              class="content-view-html innerHtml"
              [innerHTML]="article.attributes.content | safe: 'html'"
            ></div>
          </div>
        </div>
      </div>
    </cms-content>
  </div>
  <div class="see-more" *ngIf="article?.type === 'CmsArticle'">
    <div class="container">
      <div class="divider"></div>
      <h2>{{ 'articles.seeAlso' | trans }}</h2>
      <app-article-list-random *ngIf="article" [except]="article.id"></app-article-list-random>
    </div>
  </div>

  <div class="contact-box">
    <app-simple-box></app-simple-box>
  </div>
</app-container>
