import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HomeReportCrashModel } from '@app/template/home/models/home-report-crash.model';

@Component({
  selector: 'app-report-crash',
  templateUrl: 'report-crash.component.html',
  styleUrls: ['report-crash.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportCrashComponent {
  @Input() data: HomeReportCrashModel;
}
