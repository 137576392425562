import { Injectable } from '@angular/core';
import { FormErrors } from '@gkw/shared/common/models/form-errors.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseError } from '@share/common/models/http.model';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorParserService {
  static parse(httpErrorResponse: HttpErrorResponse): FormErrors {
    const rawErrors: ResponseError = ApiErrorParserService.getRawErrors(httpErrorResponse);

    const parsedErrors: FormErrors = rawErrors.reduce((previous, current) => {
      return {
        ...previous,
        [current.id ?? current.status]: {
          text: current.title
        }
      };
    }, {});

    return parsedErrors;
  }

  static getRawErrors(httpErrorResponse: HttpErrorResponse): Array<{ id: string; title: string }> {
    try {
      return httpErrorResponse.error.errors;
    } catch (e) {
      throw new Error('Invalid error template');
    }
  }
}
