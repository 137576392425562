import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayComponent } from '@app/template/home/components/overlay/overlay.component';
import { SearchModule } from '@app/client-core/search/search.module';
import { PromotedComponent } from '@app/template/home/components/promoted/promoted.component';
import { ElementsModule } from '@share/modules/elements/elements.module';
import { CarouselModule } from '@app/template/elements/carousel/carousel.module';
import { LogoModule } from '@app/template/layout/modules/logo/logo.module';
import { TruncateModule } from '@share/modules/truncate/truncate.module';
import { TranslatorModule } from '@share/modules/translator/translator.module';
import { RouterModule } from '@angular/router';
import { SelectModule } from '@share/modules/html/select/select.module';
import { FormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { HeadingModule } from '@app/template/elements/heading/heading.module';
import { PromotedModule } from '@app/template/elements/promoted/promoted.module';
import { PolygonModule } from '@app/template/elements/polygon/polygon.module';
import { AccordionModule } from '@app/template/elements/accordion/accordion.module';
import { PaginationModule } from '@app/template/elements/pagination/pagination.module';
import { MainBannerComponent } from '@app/template/home/components/main-banner/main-banner.component';
import { ReportCrashComponent } from '@app/template/home/components/report-crash/report-crash.component';
import { ScheduleComponent } from '@app/template/home/components/schedule/schedule.component';
import { NewsComponent } from '@app/template/home/components/news/news.component';
import { GalleryComponent } from '@app/template/home/components/gallery/gallery.component';
import { SimpleBoxModule } from '@app/template/elements/simple-box/simple-box.module';
import { ArticleModule } from '@app/client-core/article/article.module';

@NgModule({
  declarations: [
    GalleryComponent,
    NewsComponent,
    ScheduleComponent,
    ReportCrashComponent,
    MainBannerComponent,
    OverlayComponent,
    PromotedComponent,
  ],
  imports: [
    CommonModule,
    SearchModule,
    CarouselModule,
    ElementsModule,
    LogoModule,
    RouterModule,
    NgbCarouselModule,
    NgbPaginationModule,
    PromotedModule,
    SelectModule,
    FormsModule,
    TranslatorModule,
    PolygonModule,
    TruncateModule,
    AccordionModule,
    PaginationModule,
    HeadingModule,
    SimpleBoxModule,
    ArticleModule
  ],
  exports: [OverlayComponent]
})
export class HomeModule {}
