<div class="multi-carousel">
  <div class="owl-carousel owl-theme">
    <div class="item " *ngFor="let item of data;trackBy:identify">

        <img
          class="slide-image"
          [src]="item.attributes.file"
          [alt]="item.attributes.description"
          [title]="item.attributes.description">
    </div>
  </div>
  <div *ngIf="config.nav">
    <div id="owl-carousel-custom-nav"></div>
  </div>
</div>
<ng-container *ngIf="config.dots">
  <ul id='owl-carousel-custom-dots' class='owl-dots'>
    <li class='owl-dot' *ngFor="let item of data;trackBy:identify">
      <shared-hexagon-content
        [height]="15"
        [width]="15"
      ></shared-hexagon-content>
    </li>
  </ul>
</ng-container>

