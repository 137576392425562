import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatorModule } from '@share/modules/translator/translator.module';
import { RouterModule } from '@angular/router';
import { ElementsModule } from '@share/modules/elements/elements.module';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ContainerModule } from '@app/template/layout/modules/container/container.module';
import { PolygonModule } from '@app/template/elements/polygon/polygon.module';
import { PaginationModule } from '@app/template/elements/pagination/pagination.module';
import { PaginationService } from '@app/template/elements/pagination/services/pagination.service';
import { TruncateModule } from '@share/modules/truncate/truncate.module';
import { ImageZoomModule } from '@app/template/elements/image-zoom/image-zoom.module';
import {
  ArticleComponent,
  ArticleListComponent,
  ArticleListItemComponent,
  ArticleListRandomComponent
} from '@app/client-core/article/components';
import { SimpleBoxModule } from '@app/template/elements/simple-box/simple-box.module';
import { LogoModule } from '@app/template/layout/modules/logo/logo.module';

@NgModule({
  declarations: [
    ArticleListRandomComponent,
    ArticleListItemComponent,
    ArticleListComponent,
    ArticleComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        ElementsModule,
        TranslatorModule,
        PolygonModule,
        NgbPaginationModule,
        ContainerModule,
        PaginationModule,
        TruncateModule,
        ImageZoomModule,
        SimpleBoxModule,
        LogoModule
    ],
  exports: [ArticleListComponent, ArticleComponent, ArticleListItemComponent, ArticleListRandomComponent],
  providers: [PaginationService]
})
export class ArticleModule {}
