<app-container [top]="50" [bottom]="50">
  <div class="container article-content">
    <cms-content [loading]="loading" [error]="getError" [value]="articleList" (componentRefreshed)="loadArticles()">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12" *ngFor="let article of articleList; trackBy: identify; index as i">
          <app-article-list-item
            [title]="article.attributes.title"
            [description]="article.attributes.description"
            [publishedFrom]="article.attributes.publishedFrom"
            [categories]="article.attributes.categories"
            [file]="article.attributes.file"
            [slug]="article.attributes.slug"
            [link]="article.attributes.url"
            [id]="article.id"
          ></app-article-list-item>
        </div>
      </div>

      <shared-pagination [pages]="pages" [perPage]="count" (changed)="scrollToTop()"></shared-pagination>

      <ng-container class="no-data">
        <shared-no-data [title]="'articles.noData' | trans" icon="fas fa-newspaper" size="large"></shared-no-data>
      </ng-container>
    </cms-content>
  </div>

  <app-simple-box></app-simple-box>
</app-container>
