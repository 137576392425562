import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MobileEnum } from '@app/common/enums/mobile.enum';

@Injectable({
  providedIn: 'root'
})
export class ResizeListener {
  get isMobile$(): Observable<boolean> {
    return merge(this.onLoad(), this.onResize()).pipe(
      switchMap(value => {
        if (!value) {
          return this.isMobileDevice();
        }
        return of(value);
      })
    );
  }

  private onResize(): Observable<boolean> {
    return fromEvent(window, 'resize').pipe(map(() => window.innerWidth < MobileEnum.Large));
  }

  private onLoad(): Observable<boolean> {
    return of(window.innerWidth < MobileEnum.Large);
  }
  private isMobileDevice(): Observable<boolean> {
    return of(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  }
}
