<app-container [top]="50" [bottom]="100">
  <div class="container">
    <cms-content [loading]="getLoading" [value]="data">
      <ul *ngIf="data.articles?.length > 0">
        <h3>Artykuły</h3>

        <div class="row">
          <li *ngFor="let item of data.articles; trackBy: identify" class="col-lg-4 found-element article">
            <app-article-list-item
              [title]="item.attributes.title"
              [link]="item.attributes.url"
              [description]="item.attributes.content"
              [categories]="item.attributes.additionalFields?.categories"
              [publishedFrom]="item.attributes.additionalFields?.publishedAt"
              [file]="item.attributes.additionalFields?.image"
              [id]="item.id"
            ></app-article-list-item>
          </li>
        </div>
      </ul>

      <ul *ngIf="data.pages?.length > 0">
        <h3>Strony</h3>
        <li *ngFor="let item of data.pages; trackBy: identify" class="found-element">
          <a
            tabindex="0"
            (click)="navigateTo(item.attributes.url, item.attributes.isExternal)"
            (keyup.enter)="navigateTo(item.attributes.url, item.attributes.isExternal)"
            >{{ item.attributes.content }}
          </a>
        </li>
      </ul>

      <shared-pagination [perPage]="count" [pages]="pages"></shared-pagination>
      <ng-container class="no-data">
        <shared-no-data icon="fas fa-search" size="large" [title]="'search.noData' | trans"></shared-no-data>
      </ng-container>
    </cms-content>
  </div>
</app-container>
