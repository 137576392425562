import { Component, Input } from '@angular/core';
import { StaticPageDefaultModel } from '@app/client-core/static-pages/model/static-page-default.model';

@Component({
  selector: 'app-static-page-default',
  templateUrl: './static-page-default.component.html',
  styleUrls: ['./static-page-default.component.scss']
})
export class StaticPageDefaultComponent {
  @Input() data: StaticPageDefaultModel;

  getFileUrl() {
    // TODO: Poprawić typy
    if (this.data.file?.hasOwnProperty('url')) {
      return this.data.file['croppedImage'] || this.data?.file['url'];
    }
    return this.getFileAsString();
  }

  private getFileAsString(): string | null {
    if (typeof this.data?.file === 'string') {
      return this.data?.file;
    }
    return null;
  }
}
