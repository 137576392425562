import { AfterViewChecked, Component, ElementRef, Input } from '@angular/core';
import { MultiCarousel } from '../../models/multi-carousel.model';
import { MultiCarouselConfig } from '../../models/multi-carousel-config.model';
import { ComponentHelper } from '@app/common/helpers/component.helper';
import { AbstractData } from '@share/common/models/http';

declare var $: Function;

@Component({
  selector: 'app-multi-carousel',
  templateUrl: './multi-carousel.component.html',
  styleUrls: [
    './multi-carousel.component.scss'
  ]
})
export class MultiCarouselComponent extends ComponentHelper implements AfterViewChecked {
  @Input() data: Array<AbstractData<MultiCarousel>> = [];

  @Input()
  set config(config: MultiCarouselConfig) {
    this._config = Object.assign(this._config, config);
  }

  private _config: MultiCarouselConfig = {
    dots: true,
    nav: true,
    itemsOnScreen: {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    },
    autoplayHoverPause: false,
    autoplay: false,
    autoplayTimeout: 5000,
    loop: false
  };

  get config() {
    return this._config;
  }

  private isCarouselInit = false;

  constructor(private elementRef: ElementRef) {
    super();
  }

  public initCarousel(): void {
    const owl = $('.owl-carousel').owlCarousel({
      margin: 0,
      responsive: this.config.itemsOnScreen,
      dots: this.config.dots,
      nav: this.config.nav,
      dotsContainer: '#owl-carousel-custom-dots',
      navContainer: '#owl-carousel-custom-nav',
      navText: [
        `<i class="fas fa-chevron-left"></i>`,
        `<i class="fas fa-chevron-right"></i>`
      ],
      autoplay: this.config.autoplay,
      autoplayTimeout: this.config.autoplayTimeout,
      autoplayHoverPause: this.config.autoplayHoverPause,
      loop: this.config.loop
    });
    $('.owl-dots').on('click', 'li', function (e: any) {
      // @ts-ignore
      owl.trigger('to.owl.carousel', [$(this).index(), 300]);
    });
  }


  private setAriaLabels() {
    const dots = this.elementRef.nativeElement.querySelectorAll('.owl-dot');
    dots.forEach((dot: HTMLButtonElement, index: number) => {
      dot.setAttribute('aria-label', this.trans('global.goToPage') + (index + 1));
    });
  }

  getLink(url: string): string {
    return url?.length > 3 && url.substr(0, 3) === 'www' ? `//${url}` : url
  }

  ngAfterViewChecked() {
    const carousel = $('.owl-carousel');
    if (carousel.length > 0 && !this.isCarouselInit) {
      setTimeout(() => {
        this.initCarousel();
        this.setAriaLabels();
      });
      this.isCarouselInit = true;
    }
  }
}
