import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchComponent} from './components/search/search.component';
import {FormsModule} from '@angular/forms';
import {ElementsModule} from '@share/modules/elements/elements.module';
import {SearchResultsComponent} from './components/search-results/search-results.component';
import {TranslatorModule} from '@share/modules/translator/translator.module';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {ContainerModule} from '@app/template/layout/modules/container/container.module';
import {SelectModule} from '@share/modules/html/select/select.module';
import { PaginationModule } from '@app/template/elements/pagination/pagination.module';
import { PaginationService } from '@app/template/elements/pagination/services/pagination.service';
import { RouterModule } from '@angular/router';
import { SearchResultsSubheaderComponent } from '@app/client-core/search/components/search-results-subheader/search-results-subheader.component';
import { ArticleModule } from '@app/client-core/article/article.module';
import { SearchHelperService } from '@app/client-core/search/search-helper.service';

@NgModule({
  declarations: [SearchComponent, SearchResultsComponent, SearchResultsSubheaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    ElementsModule,
    TranslatorModule,
    NgbPaginationModule,
    ContainerModule,
    SelectModule,
    PaginationModule,
    RouterModule,
    ArticleModule,
  ],
  exports: [
    SearchComponent,
    SearchResultsComponent
  ],
  providers: [
    PaginationService,
    SearchHelperService
  ]
})
export class SearchModule {}
