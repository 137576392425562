import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@app/common/services/settings.service';
import { ComponentHelper } from '@app/common/helpers/component.helper';
import { VersionService } from '@share/common/services/version.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends ComponentHelper implements OnInit {
  public footer: string;
  public version: string;

  constructor(private settingsService: SettingsService, private versionService: VersionService) {
    super();
  }

  ngOnInit() {
    this.footer = this.settingsService.variables.footer.footer;
  }
}
