import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageCropper } from '@app/template/home/models/image-cropper.model';

@Component({
  selector: 'app-static-page-contact-department',
  templateUrl: 'static-page-contact-department.component.html',
  styleUrls: ['static-page-contact-department.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticPageContactDepartmentComponent {

  @Input() rightImage: boolean;
  @Input() special: boolean;
  @Input() specialTwo: boolean;
  @Input() smallUp: boolean;

  @Input() data: {
    title: string;
    bigImage: ImageCropper;
    smallImage: ImageCropper;
    people: {
      personOrPlace: string;
      contact: {
        type: 'phone' | 'email';
        value: string;
        extra: string | null;
      }[];
    }[];
  };
}
