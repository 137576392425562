export const ARTICLE_PREVIEW = 'articles';
export const STATIC_PAGE_PREVIEW = 'static-pages';
export const ESERVICE_PREVIEW = 'eservices';
export const ROUTES_PATHS = {
  profile: {
    root: 'konto',
    login: 'logowanie',
    register: 'rejestracja',
    remindPassword: 'przypomnij-haslo'
  },
  businessModules: {
    root: 'moduly-biznesowe'
  },
  eServices: {
    root: 'katalog-e-uslug'
  },
  notFoundPage: {
    root: '404'
  },
  unknownErrorPage: {
    root: '500'
  },
  maintenance: {
    root: 'przerwa-techniczna'
  },
  preview: {
    articles: 'articles',
    staticPages: 'static-pages'
  }
};
