import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CaptchaV3Service } from '@app/common/services/captcha-v3.service';
import { ConsentsService } from '@app/client-core/static-pages/components/static-page-contact/consents.service';
import { finalize, first } from 'rxjs/operators';
import { getContactFormGroup } from '@app/client-core/static-pages/components/static-page-contact/contact-form/form/contact-form.group';
import { getContactFormConfig } from '@app/client-core/static-pages/components/static-page-contact/contact-form/form/contact-form.config';
import { FacadeService } from '@app/common/services/facade.service';
import { ContactService } from '@app/client-core/static-pages/components/static-page-contact/contact.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: 'contact-form.component.html',
  styleUrls: ['contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactFormComponent implements OnInit, OnDestroy, AfterViewInit {
  formGroup: FormGroup;
  formConfig: { [id: string]: any };
  consentLoader: boolean;
  sendLoader: boolean;

  @Input() title: string;
  @Input() subtitle: string;

  constructor(
    private formBuilder: FormBuilder,
    private captchaService: CaptchaV3Service,
    private consentService: ConsentsService,
    private cdr: ChangeDetectorRef,
    private facadeService: FacadeService,
    private contactService: ContactService
  ) {
    this.initFormGroup();
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group(getContactFormGroup());
    this.formConfig = getContactFormConfig(this.facadeService.translator);
  }

  ngOnInit() {
    this.captchaService.load();
    this.getConsents();
  }

  getConsents() {
    this.consentLoader = true;
    this.cdr.detectChanges();
    this.consentService
      .getList('contact')
      .pipe(
        first(),
        finalize(() => {
          this.consentLoader = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe(response => {
        const [first] = response.data;
        this.setConsentConfig(first.id, first.attributes.fulltext);
      });
  }

  setConsentConfig(id: string, label: string) {
    this.formConfig.consent = {
      id: id,
      label: label,
      allowHtmlLabel: true
    };
  }

  ngAfterViewInit() {
    this.captchaService.toggleVisibility();
  }

  onSubmit() {
    this.sendLoader = true;
    this.cdr.detectChanges();
    this.captchaService.execute()
      .pipe(
        first()
      )
      .subscribe(
      token => {
        this.formGroup.patchValue({ captcha: token });
        this.sendForm();
      },
      () => {
        this.facadeService.toastr.error(this.facadeService.trans('contact.form.captchaError'));
        this.sendLoader = false;
        this.cdr.detectChanges();
      }
    );
  }

  sendForm() {
    this.contactService
      .submit(this.formGroup.getRawValue())
      .pipe(
        first(),
        finalize(() => {
          this.sendLoader = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe(
        () => {
          this.facadeService.toastr.success(this.facadeService.translator.trans('contact.form.successMessage'));
          this.formGroup.reset();
        },
      );
  }

  ngOnDestroy(): void {
    this.captchaService.toggleVisibility(false);
  }
}
