<nav>
  <button
    data-toggle="collapse"
    [class.open]="open"
    (click)="toggleMenu()"
    type="button"
    class="nav-toggle"
    data-target="#mobile-menu"
  >
    <svg class="ham ml-auto" viewBox="0 0 100 100" width="50">
      <path
        class="line top"
        d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
      />
      <path class="line middle" d="m 30,50 h 40" />
      <path
        class="line bottom"
        d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
      />
    </svg>
  </button>

  <div id="mobile-menu" class="navbar-collapse collapse">
      <ng-template #recursiveList let-menu>
        <li *ngFor="let item of menu">
          <ng-container *ngIf="item?.attributes?.children.length > 0; else defaultMenuItem">
            <a
              role="button"
              data-toggle="collapse"
              aria-controls="submenu"
              aria-expanded="false"
              [href]="'#' + parseId(item.attributes.title)"
              >{{ item.attributes.title }}</a
            >
            <ul [id]="parseId(item.attributes.title)" class="collapse">
              <ng-container
                [ngTemplateOutlet]="recursiveList"
                [ngTemplateOutletContext]="{ $implicit: item.attributes.children }"
              ></ng-container>
            </ul>
          </ng-container>

          <ng-template #defaultMenuItem>

            <ng-container *ngIf="item.attributes.type === 'link'; else innerRouterLink">
              <a class="nav-link" [class.highlight]="item.attributes.isHighlighted" (click)="hide()" target="_blank" [attr.href]="item.attributes.url">{{
                item.attributes.title
                }}</a>
            </ng-container>

            <ng-template #innerRouterLink>
              <a class="nav-link" [class.highlight]="item.attributes.isHighlighted" (click)="hide()" [routerLink]="['/' + item.attributes.url]">{{
                item.attributes.title
                }}</a>
            </ng-template>

          </ng-template>
        </li>
      </ng-template>
    <ul class="first-level">
      <ng-container [ngTemplateOutlet]="recursiveList" [ngTemplateOutletContext]="{ $implicit: menu }"></ng-container>
    </ul>
  </div>
</nav>
