import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatorModule } from '@share/modules/translator/translator.module';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { ElementsModule } from '@share/modules/elements/elements.module';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { FatalErrorComponent } from './components/fatal-error/fatal-error.component';

@NgModule({
  declarations: [NotFoundComponent, MaintenanceComponent, FatalErrorComponent],
  imports: [
    CommonModule,
    RouterModule,
    ElementsModule,
    TranslatorModule
  ],
  exports: [
    NotFoundComponent,
    MaintenanceComponent
  ]
})
export class ErrorPagesModule {
}
