import { NgModule } from '@angular/core';
import { BreadcrumbsComponent } from '@app/template/layout/modules/breadcrumbs/components/breadcrumbs/breadcrumbs.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslatorModule } from '@share/modules/translator/translator.module';

@NgModule({
  declarations: [
    BreadcrumbsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslatorModule
  ],
  exports: [
    BreadcrumbsComponent
  ]
})
export class BreadcrumbsModule {}
