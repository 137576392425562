import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';

declare let $: Function;

@Component({
  selector: 'app-high-contrast',
  templateUrl: './high-contrast.component.html',
  styleUrls: ['./high-contrast.component.scss']
})
export class HighContrastComponent implements OnInit, AfterViewInit {
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const contrast: string = localStorage.getItem('contrast') as string;
    if (contrast) {
      switch (contrast) {
        case 'blackwhite':
          this.addBlackWhite();
          break;
        case 'yellowblack':
          this.addYellowBlack();
          break;
      }
    }
  }

  removeContrast() {
    this.renderer.removeClass(document.body, localStorage.getItem('contrast') as string);
    this.renderer.setStyle(document.body, 'color', '');
    this.renderer.setStyle(document.body, 'background-color', '');

    localStorage.setItem('contrast', 'normal');
  }

  addYellowBlack() {
    this.removeContrast();
    this.renderer.addClass(document.body, 'yellowblack');

    this.renderer.setStyle(document.body, 'background-color', 'black');
    this.renderer.setStyle(document.body, 'color', 'yellow');

    localStorage.setItem('contrast', 'yellowblack');
  }

  addBlackWhite() {
    this.removeContrast();
    this.renderer.addClass(document.body, 'blackwhite');

    this.renderer.setStyle(document.body, 'background-color', 'white');
    this.renderer.setStyle(document.body, 'color', 'black');

    localStorage.setItem('contrast', 'blackwhite');
  }
}
