import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatorModule } from '@share/modules/translator/translator.module';
import { CookieInformationBarComponent } from './components/cookie-information-bar/cookie-information-bar.component';
import { ElementsModule } from '@share/modules/elements/elements.module';

@NgModule({
  declarations: [CookieInformationBarComponent],
  imports: [
    CommonModule,
    ElementsModule,
    TranslatorModule
  ],
  exports: [
    CookieInformationBarComponent
  ]

})
export class CookiesModule {
}
