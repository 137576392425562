import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractData } from '@share/common/models/http';
import { PagesService } from '@app/common/services/pages.service';
import { finalize, first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-schedule',
  templateUrl: 'schedule.component.html',
  styleUrls: ['schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleComponent implements OnChanges {
  @Input() data: {
    title: string;
    subtitle: string;
    pageId: string;
  };

  scheduleElements: {
    boxTitle: string;
    boxSubtitle: string;
    boxPhone: string;
    schedule: { cities: string; date: string }[];
  };

  url: string;

  constructor(private pageService: PagesService, private cdr: ChangeDetectorRef, private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.currentValue.pageId) {
      this.pageService
        .getOne(changes.data.currentValue.pageId)
        .pipe(
          first(),
          finalize(() => this.cdr.detectChanges())
        )
        .subscribe(e => {
          this.url = e.data.id.replace(',', '/');
          this.scheduleElements = e.data.attributes.content.content;
          this.scheduleElements.schedule = this.scheduleElements.schedule.filter(
            (item: any, index: number) => index < 4
          );
        });
    }
  }

  redirectToMore() {
    this.router.navigate([this.url]);
  }
}
