<div class="hexagon-content-container"
     [class.hexagon-content-hoverable]="isHover">
  <div class="hexagon-content-svg">
    <svg [attr.viewBox]="(0 - borderWidth/20) + ' 0 ' + (90 + borderWidth/10) + ' 80'"
         xmlns="http://www.w3.org/2000/svg"
         [attr.height]="height"
         [attr.fill]="bgGradient ? 'url('+location+'#gradient-'+innerShadowID+')' : null"
         [attr.width]="width"
         [style.filter]="isOuterShadow ? 'drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 5px)' : false"
         [ngStyle]="{'filter': isOuterShadow ? 'drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 5px)' : 'none'}"
    >
      <defs>
        <linearGradient id="gradient-{{innerShadowID}}" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" [attr.stop-color]="'var(--bannerLeftGradient)'"/>
          <stop offset="100%" [attr.stop-color]="'var(--bannerRightGradient)'"/>
        </linearGradient>
        <filter class="inner-shadow" id="inner-shadow-{{innerShadowID}}">
          <feFlood [attr.flood-color]="'rgba(0,0,0,' + innerShadowConfig.alpha + ')'"/>
          <feComposite in2="SourceAlpha" operator="out"/>
          <feGaussianBlur [attr.stdDeviation]="innerShadowConfig.blur" result="blur"/>
          <feComposite operator="atop" in2="SourceGraphic"/>
        </filter>
      </defs>

      <pattern *ngIf="bgImage" [id]="innerShadowID" patternUnits="userSpaceOnUse" width="100%" height="100%">
        <image [attr.xlink:href]="bgImage" x="0" y="0" width="90" height="90"/>
      </pattern>

      <polygon
        *ngIf="!edgeRounded"
        class="hexagon-content-polygon"
        points="20,0 70,0 90,40 70,80 20,80 0,40"
        [attr.fill]="bgImage ? 'url('+location+'#' + innerShadowID + ')' : bgColor"
        [attr.stroke]="borderGradient ? 'url('+location+'#gradient-'+innerShadowID+')' : borderColor"
        [attr.stroke-width]="borderWidth / 10"
        [attr.filter]="isInnerShadow ? 'url('+location+'#inner-shadow-'+innerShadowID+')' : false"
      />
      <path
        *ngIf="edgeRounded"
        [attr.stroke]="borderGradient ? 'url('+location+'#gradient-'+innerShadowID+')' : borderColor"
        [attr.stroke-width]="borderWidth/10"
        [attr.fill]="bgImage ? 'url('+location+'#' + innerShadowID + ')' : bgColor"
        d="M4.999999999999999 47.63139720814413Q0 38.97114317029974 4.999999999999999 30.31088913245535L17.5
         8.660254037844387Q22.5 0 32.5 0L57.5 0Q67.5 0 72.5 8.660254037844387L85 30.31088913245535Q90
          38.97114317029974 85 47.63139720814413L72.5 69.2820323027551Q67.5 77.94228634059948 57.5
           77.94228634059948L32.5 77.94228634059948Q22.5 77.94228634059948 17.5 69.2820323027551Z">
      </path>
    </svg>
  </div>

  <div
    class="hexagon-content-body"
    [style.width.px]="width"
    [style.height.px]="height"
  >
    <ng-content></ng-content>
  </div>
</div>
