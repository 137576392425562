import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'cms-subheader-title',
  templateUrl: 'subheader-title.component.html',
  styleUrls: ['subheader-title.component.scss']
})
export class SubheaderTitleComponent {

  isEditable: boolean = false;

  @Input() title: string;
  @Input() isMobile: boolean;
  @Input() set editable(editable: boolean) {
    this.isEditable = editable;
    setTimeout(() => this.text.nativeElement.focus());
  };

  @ViewChild('text') text: ElementRef;

  @Output() onSubmitEditable: EventEmitter<string> = new EventEmitter<string>();

  onSubmit() {
    this.isEditable = false;
    this.onSubmitEditable.emit(this.text.nativeElement.innerText);
  }

}
