import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private _isHomePreview: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isHomePreview$: Observable<boolean> = this._isHomePreview.asObservable().pipe(distinctUntilChanged());

  set isHomePreview(value: boolean) {
    this._isHomePreview.next(value);
  }
}
