<div class="layer top">
  <svg viewBox="0 0 1440 449" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1445 417.772V40.9902C1445 40.9902 990 0 721.5 0C453 0 -2 40.9902 -2 40.9902V417.772C-2 417.772 412.5 448.5 721.5 448.5C1030.5 448.5 1445 417.772 1445 417.772Z"/>
  </svg>
</div>

<section>
  <h2 class="sr-only">Galeria</h2>
  <app-multi-carousel [data]="parsedData" [config]="config"></app-multi-carousel>
</section>

<div class="layer bottom">
  <svg viewBox="0 0 1440 449" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1445 417.772V40.9902C1445 40.9902 990 0 721.5 0C453 0 -2 40.9902 -2 40.9902V417.772C-2 417.772 412.5 448.5 721.5 448.5C1030.5 448.5 1445 417.772 1445 417.772Z"/>
  </svg>
</div>
