import { Component, OnInit } from '@angular/core';
import { ComponentHelper } from '@app/common/helpers/component.helper';

@Component({
  selector: 'app-fatal-error',
  templateUrl: './fatal-error.component.html',
  styleUrls: ['./fatal-error.component.scss']
})
export class FatalErrorComponent extends ComponentHelper implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

  goToHome(): void {
    window.location.href = '/';
  }

}
