<div *ngIf="!isCookieAccepted" class="cookie-component">
  <div class="cookie-img"></div>
  <span [innerHTML]="cookieText | safe : 'html'"></span>
  <cms-button
    [id]="'cookieAccept'"
    [text]="'global.accept' | trans"
    [icon]="buttonIcons.Check"
    [btnClass]="[buttonClasses.SecondaryOutline]"
    [type]="buttonTypes.Button"
    (clicked)="accept()"
  ></cms-button>
</div>
