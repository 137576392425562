import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Article } from '@app/client-core/article/models/article.model';
import { ArticlesService } from '@app/client-core/article/services/articles.service';
import { AbstractData } from '@share/common/models/http';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { ElementService } from '@app/client-core/dynamic-content/service/element.service';
import { distinctUntilChanged, finalize, first, takeUntil } from 'rxjs/operators';
import { PaginationService } from '@app/template/elements/pagination/services/pagination.service';
import { FacadeService } from '@app/common/services/facade.service';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleListComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  articleList: Array<AbstractData<Article>> = [];
  pages: number = 1;
  count: number = 12;
  loading: boolean;
  getError: boolean;

  constructor(
    private articlesService: ArticlesService,
    private activatedRoute: ActivatedRoute,
    private paginationService: PaginationService,
    private elementService: ElementService,
    private facadeService: FacadeService,
    private cdr: ChangeDetectorRef
  ) {
    this.facadeService.subheader.config.title = this.facadeService.translator.trans('articles.title');
    this.facadeService.subheader.config.displayBreadcrumbs.next(true);
  }

  ngOnInit() {
    this.setListeners();
  }

  setLoader(value: boolean) {
    this.loading = value;
    this.cdr.detectChanges();
  }

  identify(index: number) {
    return index;
  }

  loadArticles(page: number = 1, category: string | null = null) {
    this.setLoader(true);
    this.articlesService
      .getList(page, this.count , category)
      .pipe(
        first(),
        finalize(() => this.setLoader(false))
      )
      .subscribe(
        response => {
          this.articleList = response.data;
          const meta = response.meta;
          if (meta && typeof meta.pages === 'number') {
            this.pages = meta.pages;
          }
        },
        () => (this.getError = true)
      );
  }

  goToArticle(slug: string) {
    this.facadeService.router.navigate([slug], { relativeTo: this.activatedRoute });
  }

  scrollToTop() {
    this.facadeService.scrollTo.animate('.article-content');
  }

  private setListeners() {
    const pagination$ = this.paginationService.currentPage.pipe(distinctUntilChanged());
    const objectId$ = this.elementService.getObjectId.pipe(distinctUntilChanged());
    combineLatest([pagination$, objectId$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([page, objectId]) => {
        this.loadArticles(page, objectId);
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
