import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AbstractData } from '@share/common/models/http.model';
import { MenuModel } from '@app/client-core/menu/models/menu.model';
import { ComponentHelper } from '@app/common/helpers/component.helper';

@Component({
  selector: 'app-sitemap-item',
  templateUrl: './sitemap-item.component.html',
  styleUrls: [
    './sitemap-item.component.scss'
  ]
})
export class SitemapItemComponent extends ComponentHelper implements OnInit, OnDestroy {
  @Input() item: AbstractData<MenuModel>;
  public listenClickFunc: Function;

  constructor(private renderer: Renderer2,
              private _elementRef: ElementRef) {
    super();
  }

  public ngOnInit() {
    this.onClickListener();
  }

  public openInNewTab(event: Event, url: string) {
    event.preventDefault();
    event.stopPropagation();
    window.open(url);
  }

  private onClickListener() {
    this.listenClickFunc = this.renderer.listen(
      this._elementRef.nativeElement, 'click', (event) => this.service.utils.navigateTo(event)
    );
  }

  public ngOnDestroy() {
    this.listenClickFunc();
  }
}
