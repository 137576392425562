import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from '@app/template/layout/modules/logo/components/logo/logo.component';
import { RouterModule } from '@angular/router';
import { TranslatorModule } from '@share/modules/translator/translator.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslatorModule
  ],
  declarations: [
    LogoComponent
  ],
  exports: [
    LogoComponent
  ]
})
export class LogoModule {}
