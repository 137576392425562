import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ComponentHelper } from '@app/common/helpers/component.helper';
import { DOCUMENT } from '@angular/common';
import { SettingsService } from '@app/common/services/settings.service';
import { LazyLoaderService } from '@app/common/services/lazy-loader.service';
import { ThemesEnum } from '@app/common/models/themes.enum';
import { ResizeListener } from '@app/common/services/resize-listener.service';
import { SearchService } from '@app/client-core/search/services/search.service';
import { fromEvent, Observable } from 'rxjs';
import { MenuService } from '@app/client-core/menu/services/menu.service';
import { debounceTime, filter, finalize, first, takeUntil, tap } from 'rxjs/operators';
import { AbstractData } from '@share/common/models/http';
import { MenuModel } from '@app/client-core/menu/models/menu.model';

declare let $: Function;

@Component({
  selector: 'app-header',
  templateUrl: './header-container.component.html',
  styleUrls: [
    './header-container.component.scss',
    './header-container-contrast.component.scss',
  ]
})
export class HeaderContainerComponent extends ComponentHelper implements OnInit, AfterViewInit {
  isAuthenticated: boolean = false;
  search: string;

  @ViewChild('alertBtn', { read: ViewContainerRef }) alertBtn: ViewContainerRef;
  @ViewChild('menu', { read: ViewContainerRef }) menu: ViewContainerRef;

  private startDistance = 10;

  isMobileSearchActive$: Observable<boolean>;
  isValidDefaultMenuWidth: boolean;
  isMobileMenuMode: boolean;
  isDefaultMenuWidthChecking: boolean;
  isFetchMenuLoading: boolean;
  menuData: AbstractData<MenuModel>[];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private settings: SettingsService,
    private loader: LazyLoaderService,
    public resizeListener: ResizeListener,
    private searchService: SearchService,
    private menuService: MenuService,
    private _elementRef: ElementRef
  ) {
    super();
  }

  get isSubpage(): boolean {
    return !this.service.router.isActive('', true);
  }

  get isAlertModuleEnabled(): boolean {
    return this.settings.isModuleActive(ThemesEnum.ALERT);
  }

  get isMenuModuleEnabled(): boolean {
    return this.settings.isModuleActive(ThemesEnum.MENU);
  }

  /** Metoda sprawdzająca, czy zawartość menu dla desktop'ów nie wystaje poza szerokość okna/headera. */
  get isValidHeaderContentWidth(): boolean {
    const contentElementRef: HTMLElement = this.document.querySelector('#main-app-header .content') as HTMLElement;
    const contentElementChildren: HTMLElement[] = Array.from(contentElementRef.children) as HTMLElement[];
    const contentElementAvailableWidth: number = contentElementRef.offsetWidth
      - (parseInt(getComputedStyle(contentElementRef).paddingLeft))
      - (parseInt(getComputedStyle(contentElementRef).paddingRight))
      - 30 // ScrollBar with small offset.
    ;

    let contentChildrenWidth: number = 0;

    contentElementChildren.forEach(child => {
      contentChildrenWidth += child.offsetWidth;
    });

    return contentChildrenWidth <= contentElementAvailableWidth
  }

  ngOnInit() {
    this.fetchMenu();
    window.addEventListener('scroll', () => this.scroll(), true);
    this.scroll();
    this.isMobileSearchActive$ = this.searchService.isActive$;
    this.searchService.isActive$.pipe(takeUntil(this.destroyed$)).subscribe(isActive => {
      if (isActive) {
        this.menuService.isMobileMenuActive$.next(false);
      }
    });
    this.handleOutsideClick();
  }

  ngAfterViewInit() {
    this.generateAlertButton();
    this.generateMenu();
  }

  private setMenuModeChangeListener() {
    this.resizeListener.isMobile$.pipe(
      takeUntil(this.destroyed$),
      tap((value) => this.isMobileMenuMode = value),
      filter((value) => !value),
      tap(() => {
        this.isDefaultMenuWidthChecking = true;
        this.isValidDefaultMenuWidth = true; // Pokazanie menu, aby JS mógł obliczyć szerokość wewnątrz komponentu.
      }),
      debounceTime(500),
    ).subscribe((value) => {
      this.setIsMobileMenuMode();
  });
  }

  private fetchMenu() {
    this.isFetchMenuLoading = true;

    this.menuService
      .getList()
      .pipe(
        first(),
        finalize(() => this.isFetchMenuLoading = false)
      )
      .subscribe(
        response => {
          this.menuData = response.data;
          this.setMenuModeChangeListener();
        }
      );
  }

  private setIsMobileMenuMode(): void {
    // setTimeout z racji wymaganego opóźnienia przy obliczaniu dostępnej szerokosci.
    setTimeout(() => {
      this.isValidDefaultMenuWidth = this.isValidHeaderContentWidth;
    });

    this.isDefaultMenuWidthChecking = false;
  }

  generateAlertButton() {
    if (this.alertBtn && this.isAlertModuleEnabled) {
      this.loader.load(ThemesEnum.ALERT, this.alertBtn);
    }
  }

  generateMenu() {
    if (this.menu) {
      this.menu?.clear();
      if (this.isMenuModuleEnabled || this.isAuthenticated) {
        this.loader.load(ThemesEnum.MENU, this.menu);
      }
    }
  }

  handleOutsideClick() {
    const clickEvent$ = fromEvent(document, 'click');
    clickEvent$
      .pipe(
        takeUntil(this.destroyed$),
        filter(e => !this._elementRef.nativeElement.contains(e.target)),
        tap(e => {
          this.searchService.isActive$.next(false);
        })
      )
      .subscribe();
  }


  private scroll() {
    const scrollTop = $(this.document).scrollTop(),
      header = $('header');
    if (scrollTop >= this.startDistance) {
      header.addClass('sticked');
    } else {
      header.removeClass('sticked');
    }
  }

  onComponentRefresh() {
    this.fetchMenu();
  }
}
