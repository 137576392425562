import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FacadeService } from '@app/common/services/facade.service';
import { HomeIntroModel } from '@app/template/home/models/home-intro.model';

@Component({
  selector: 'app-main-banner',
  templateUrl: 'main-banner.component.html',
  styleUrls: ['main-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainBannerComponent {

  @Input() data: HomeIntroModel;

  constructor(private facadeService: FacadeService) {
  }

  scrollDown() {
    this.facadeService.scrollTo.animate('#promoted', 800, 100);
  }

}
