import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '@app/client-core/search/services/search.service';
import { SearchItem } from '@app/client-core/search/models/search-item.model';
import { AbstractData } from '@share/common/models/http';
import { NavigationExtras } from '@angular/router';
import { SearchType } from '@app/template/layout/modules/header/interfaces/subheader-config.interface';
import { fromEvent, Observable, Subject } from 'rxjs';
import { filter, finalize, first, takeUntil, tap } from 'rxjs/operators';
import { UtilsService } from '@share/common/services/utils.service';
import { SelectModel } from '@share/modules/html/common/interfaces/select-model.interface';
import { SettingsService } from '@app/common/services/settings.service';
import { ThemesEnum } from '@app/common/models/themes.enum';
import { FacadeService } from '@app/common/services/facade.service';
import { ResizeListener } from '@app/common/services/resize-listener.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  private _value = '';
  private readonly minLength: number = 3;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('input') input: ElementRef;
  @Input() mobile: boolean;
  @Input() placeholder: string = 'search.button';

  data: { articles: SearchItem[]; pages: SearchItem[] } = { articles: [], pages: [] };
  availablesTypes: Array<AbstractData<SelectModel>> = [];
  type: SearchType = SearchType.Default;
  searchSelectId: string;
  searchId: string;
  showResultsBox: boolean;
  loading: boolean;
  error: boolean;
  forceActive: boolean;
  isActive$: Observable<boolean>;


  constructor(
    private searchService: SearchService,
    private _elementRef: ElementRef,
    private utils: UtilsService,
    private settingsService: SettingsService,
    private cdr: ChangeDetectorRef,
    private facadeService: FacadeService,
  ) {
    this.availablesTypes = this.searchService.getAvailableSearchTypes();
    const newId: string = this.utils.makeId();
    this.searchSelectId = `search-type-${newId}`;
    this.searchId = `search-input-${newId}`;
  }

  markAsActive() {
    this.searchService.isActive$.next(true);
    setTimeout(() => {
      this.forceActive = true;
      this.input.nativeElement.focus();
    }, 500)
  }

  markAsInactive(){
    this.searchService.isActive$.next(false);
    this.forceActive = false;
    this.showResultsBox = false;
  }

  onValueChange(value: string) {
    if (value.length >= 3) {
      this.showResultsBox = true;
      this.searchRequest(value);
    } else {
      this.showResultsBox = false;
    }
    this.value = value;
  }

  ngOnInit() {
    this.setSearchType();
    this.handleOutsideClick();
    this.isActive$ = this.searchService.isActive$;
  }

  private setSearchType() {
    this.facadeService.subheader.getConfig().subscribe(config => {
      this.type = config.searchType || SearchType.Default;
    });
  }

  handleOutsideClick() {
    const clickEvent$ = fromEvent(document, 'click');
    clickEvent$
      .pipe(
        takeUntil(this.destroy$),
        filter(e => !this._elementRef.nativeElement.contains(e.target)),
        tap(e => {
          this.clear();
          this.markAsInactive();
        })
      )
      .subscribe();
  }

  get value() {
    return this._value;
  }

  set value(data: string) {
    this._value = data;
  }

  hasMinLength(value: string) {
    const valid = value.length >= this.minLength;
    if (!valid) {
      this.clearData();
    }
    return valid;
  }

  search() {
    if (this.hasMinLength(this.value)) {
      this.markAsInactive();
      this.facadeService.router.navigate(['search', this._value], this.getNavigationExtras()).then(onfulfilled => {
        if (onfulfilled) {
          this.clear();
        }
      });
    }
  }

  private getNavigationExtras() {
    const extra: NavigationExtras = { queryParams: null };
    if (this.type !== SearchType.Default) {
      extra.queryParams = { type: this.type };
    }
    return extra;
  }

  setLoader(value: boolean) {
    this.loading = value;
    this.cdr.detectChanges();
  }

  searchRequest(value: string) {
    this.setLoader(true);
    this.clearData();
    this.searchService
      .getList(value, this.type)
      .pipe(
        first(),
        finalize(() => this.setLoader(false))
      )
      .subscribe(response => {
        this.clearData();
        response.data?.forEach((item: any) => {
          if (item.type === 'Page') {
            this.data = { articles: [...this.data.articles], pages: [...this.data.pages, item] };
          } else if (item.type === 'CmsArticle') {
            this.data = { articles: [...this.data.articles, item], pages: [...this.data.pages] };
          }
        });
      });
  }

  private clear() {
    this._value = '';
    this.clearData();
    this.showResultsBox = false;
  }

  private clearData() {
    this.data = {
      articles: [],
      pages: []
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
