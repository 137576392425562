import { Component, Input } from '@angular/core';

@Component({
  selector: 'cms-subheader-description',
  templateUrl: 'subheader-description.component.html',
  styleUrls: ['subheader-description.component.scss']
})
export class SubheaderDescriptionComponent {
  @Input() text: string;
  @Input() isMobile: boolean;
}
