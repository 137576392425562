import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ComponentShareHelper } from '@share/common/helpers/component-share.helper';
import { FacadeShareService } from '@share/common/services/facade-share.service';
import { ActivatedRoute, Params } from '@angular/router';

@Injectable()
export class PaginationService extends ComponentShareHelper {
  private firstPage: number = 1;
  private _page: BehaviorSubject<number> = new BehaviorSubject<number>(this.firstPage);

  constructor(private facadeShareService: FacadeShareService,
              private activatedRoute: ActivatedRoute) {
    super(facadeShareService);
    this.setParamsListener();
  }

  public setParamsListener() {
    return this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.page) {
        this.page = parseInt(params.page, 10);
      } else {
        this._page.next(this.firstPage);
      }
    });
  }

  private changeQueryParams(value: number) {
    setTimeout(() => {
      this.facadeShareService.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {page: value === this.firstPage ? null : value}
      });
    });
  }

  public set page(value: number) {
    if (value !== this.page) {
      this._page.next(value);
      this.changeQueryParams(value);
    }
  }

  public get page(): number {
    return this._page.getValue();
  }

  public get currentPage(): Observable<number> {
    return this._page.asObservable();
  }
}
