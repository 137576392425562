<h2>{{item.attributes.title}}</h2>
<ul>
  <li *ngFor="let subitem of item.attributes.children;trackBy:identify">
    <a *ngIf="subitem.attributes.type !== 'link'; else href"
       [routerLink]="[subitem.attributes.url]">{{subitem.attributes.title}}</a>
    <ng-template #href>
      <a [href]="subitem.attributes.url" target="_blank">{{subitem.attributes.title}}</a>
    </ng-template>
  </li>
</ul>
