import { AppConfigCMS } from '@share/common/interfaces/app-config.interface';

declare const APP_CONFIG: AppConfigCMS;
export const URL = APP_CONFIG.API_URL;
const PREFIX = APP_CONFIG.PREFIX;
const SERVER = `${URL}/${PREFIX}`;

export const API = {
  AUTH: {
    LOGIN: `${SERVER}/login`,
    LOGOUT: `${SERVER}/logout`,
    CONSENTS: `${SERVER}/consents`,
    FORGOT_PASSWORD: `${SERVER}/account/resetPassword/sendEmail`,
    VALIDATE_TOKEN: `${SERVER}/account/resetPassword/checkToken`,
    CHANGE_PASSWORD: `${SERVER}/account/resetPassword/change`,
    ACTIVATE_ACCOUNT: `${SERVER}/account/confirm`
  },
  COMMON: {
    BREADCRUMBS: `${SERVER}/breadcrumbs`
  },
  ARTICLES: {
    ROOT: `${SERVER}/articles`,
    RANDOM: `${SERVER}/articles/random`
  },
  SETTINGS: {
    ROOT: `${SERVER}/settings/bootstrap`,
    GET_LAYOUT: `${SERVER}/settings/layout`
  },
  SEARCH: {
    ROOT: `${SERVER}/search/autocomplete`,
    FULL: `${SERVER}/search/getResult`
  },
  PROMOTED: {
    ROOT: `${SERVER}/promoted`
  },
  BANNERS: {
    ROOT: `${SERVER}/banners`
  },
  MENU: {
    ROOT: `${SERVER}/menu`,
    SITEMAP: `${SERVER}/menu/sitemap`
  },
  DYNAMIC_CONTENT: {
    ROOT: `${SERVER}/search/getMetadata`
  },
  CATEGORY: {
    ROOT: `${SERVER}/categories`
  },
  PROFILE: {
    ROOT: `${SERVER}/account`
  },
  ALERTS: {
    ROOT: `${SERVER}/alerts`
  },
  BUSINESS_MODULES: {
    ROOT: `${SERVER}/businessModules`,
  },
  PAGES: {
    ROOT: `${SERVER}/pages`
  },
  PREVIEW: {
    ROOT: `${SERVER}/preview`
  },
  INSTITUTION: {
    ROOT: 'institution'
  },
  ASSETS: {
    DEFAULT_SETTINGS: 'source/default_settings.json'
  }
};
