import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Nullable } from '@share/common/types/utilities.types';

@Injectable({
  providedIn: 'root'
})
export class ElementService {
  private _objectId: BehaviorSubject<Nullable<string>> = new BehaviorSubject<Nullable<string>>(null);
  private _sameComponentNavigation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get getObjectId(): Observable<Nullable<string>> {
    return this._objectId.asObservable();
  }

  public get objectId(): Nullable<string> {
    return this._objectId.getValue();
  }

  public set objectId(value: Nullable<string>) {
    this._objectId.next(value);
  }

  public get sameComponentNavigation(): Observable<boolean> {
    return this._sameComponentNavigation.asObservable();
  }

  public set loaded(value: boolean) {
    this._sameComponentNavigation.next(value);
  }

}
