import { Component, Input } from '@angular/core';
import { UtilsService } from '@share/common/services/utils.service';

@Component({
  selector: 'shared-hexagon-content',
  templateUrl: './hexagon-content.component.html',
  styleUrls: ['./hexagon-content.component.scss']
})
export class HexagonContentComponent {
  @Input() bgColor: string;
  @Input() borderColor: string;
  @Input() borderWidth: number = 0;
  @Input() width: number = 100;
  @Input() height: number = 100;
  @Input() isInnerShadow: boolean = false;
  @Input() innerShadowConfig: { alpha: number, blur: number } = {alpha: 0.3, blur: 2};
  @Input() isOuterShadow: boolean = false;
  @Input() isHover: boolean = false;
  @Input() bgGradient: boolean = false;
  @Input() borderGradient: boolean = false;
  @Input() edgeRounded: boolean = false;
  @Input() bgImage: string = '';

  public innerShadowID: string = '';

  constructor(private utils: UtilsService) {
    this.innerShadowID = this.utils.makeId();
  }

  public get location() {
    return window.location.href;
  }


}
