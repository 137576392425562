import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { ModalModule } from '@share/modules/modal/modal.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from '@app/template/layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceLocator } from '@share/common/services/locater.service';
import { BOOTSTRAP_PROVIDERS } from '@app/common/constants/bootstrap.provider';
import { ToastrModule } from 'ngx-toastr';
import { ErrorPagesModule } from '@app/template/layout/modules/error-pages/error-pages.module';
import { HAMMER_PROVIDER } from '@app/common/constants/hammer.provider';
import { COMMON } from '@share/common/constants/common';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { CookieService } from 'ngx-cookie-service';
import { LazyLoaderService } from '@app/common/services/lazy-loader.service';
import { lazyArrayToObj } from '@app/common/constants/lazy-widgets';
import { LAZY_WIDGETS } from '@app/common/constants/tokens';
import { ReactiveFormsModule } from '@angular/forms';

registerLocaleData(localePl, COMMON.LOCALE.PL);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ModalModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    ErrorPagesModule,
    LayoutModule,
  ],
  providers: [
    ...BOOTSTRAP_PROVIDERS,
    ...HAMMER_PROVIDER,
    CookieService,
    { provide: LAZY_WIDGETS, useFactory: lazyArrayToObj },
    LazyLoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
