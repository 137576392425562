import { NgModule } from '@angular/core';
import { PolygonModule } from '../polygon/polygon.module';
import { PromotedListComponent } from './components/promoted-list/promoted-list.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TruncateModule } from '@share/modules/truncate/truncate.module';
import { ElementsModule } from '@share/modules/elements/elements.module';
import { PromotedItemIconComponent } from './components/promoted-item-icon/promoted-item-icon.component';
import { TranslatorModule } from '@share/modules/translator/translator.module';

@NgModule({
  declarations: [
    PromotedListComponent,
    PromotedItemIconComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        PolygonModule,
        TruncateModule,
        ElementsModule,
        TranslatorModule
    ],
  exports: [
    PromotedListComponent
  ]
})
export class PromotedModule {
}
