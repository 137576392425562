import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2
} from '@angular/core';
import { AbstractData } from '@share/common/models/http';
import { MenuModel } from '@app/client-core/menu/models/menu.model';
import { MenuService } from '@app/client-core/menu/services/menu.service';
import { FacadeService } from '@app/common/services/facade.service';

@Component({
  selector: 'app-default-menu',
  templateUrl: './default-menu.component.html',
  styleUrls: ['./default-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultMenuComponent {
  @Input() menu: AbstractData<MenuModel>[];
  @Input() isMenuWidthChecking: boolean;
  @Input() isLoading: boolean;

  @Output() refreshed: EventEmitter<void> = new EventEmitter<void>();

  getError: boolean;
  open: { [id: string]: boolean } = {};

  constructor(
    private menuService: MenuService,
    private facadeService: FacadeService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private _elementRef: ElementRef
  ) {}

  onMouseOver(id: string) {
    this.open[id] = true;
  }

  hideSubmenu() {
    for(let key in this.open) {
      this.open[key] = false;
    }
  }

  onComponentRefresh() {
    this.refreshed.emit();
  }
}
