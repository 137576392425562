import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { ResponseArray, ResponseObject } from '@share/common/models/http';
import { RequestOptionsModel } from '@share/common/interfaces/request-options.interface';
import { ApiErrorParserService } from '@app/common/services/api-error-parser.service';
import { ApiPreprocessorService } from '@app/common/services/api-preprocessor.service';
import { ResponseDefault } from '@share/common/models/http.model';
import { AppConfigPortal } from '@share/common/interfaces/app-config.interface';

declare var APP_CONFIG: AppConfigPortal;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _bundle: string = '';

  constructor(private http: HttpClient, private preprocessor: ApiPreprocessorService) {}

  public setBundle(bundle: string) {
    this._bundle = bundle;
  }

  get<T>(
    endpoint: string,
    params: HttpParams = new HttpParams(),
    options: RequestOptionsModel = {}
  ): Observable<ResponseObject<T>> {
    return this.http
      .get<ResponseObject<T>>(this.preprocessor.prepareUrl(endpoint), { params })
      .pipe(first());
  }

  getAll<T>(
    endpoint: string,
    params: HttpParams = new HttpParams(),
    options: RequestOptionsModel = {}
  ): Observable<ResponseArray<T>> {
    return this.http
      .get<ResponseArray<T>>(this.preprocessor.prepareUrl(endpoint), { params })
      .pipe(first());
  }

  post(
    endpoint: string,
    body: Object = {},
    params: HttpParams = new HttpParams(),
    options: RequestOptionsModel = {}
  ): Observable<ResponseDefault> {
    const requestData = this.preprocessor.build(options.bundle || this._bundle, body, options);
    return this.http
      .post<ResponseDefault>(this.preprocessor.prepareUrl(endpoint), JSON.stringify(requestData), { params })
      .pipe(
        first(),
        catchError(e => throwError(ApiErrorParserService.parse(e)))
      );
  }

  put(
    endpoint: string,
    body: Object = {},
    options: RequestOptionsModel = {},
    params: HttpParams = new HttpParams()
  ): Observable<ResponseDefault> {
    const requestData = this.preprocessor.build(options.bundle || this._bundle, body, options);

    return this.http
      .put<ResponseDefault>(this.preprocessor.prepareUrl(endpoint), JSON.stringify(requestData), { params })
      .pipe(
        first(),
        catchError(e => throwError(ApiErrorParserService.parse(e)))
      );
  }

  patch(
    endpoint: string,
    body: Object = {},
    options: RequestOptionsModel = {},
    params: HttpParams = new HttpParams()
  ): Observable<ResponseDefault> {
    const requestData = this.preprocessor.build(options.bundle || this._bundle, body, options);

    return this.http
      .patch<ResponseDefault>(this.preprocessor.prepareUrl(endpoint), JSON.stringify(requestData), { params })
      .pipe(
        first(),
        catchError(e => throwError(ApiErrorParserService.parse(e)))
      );
  }

  assets<T>(path: string): Observable<T> {
    return this.http.get<T>(`${APP_CONFIG.ASSETS_URL}/${path}`).pipe(first());
  }

  delete(endpoint: string): Observable<ResponseDefault> {
    return this.http.delete<ResponseDefault>(this.preprocessor.prepareUrl(endpoint)).pipe(first());
  }
}
