export enum ThemesEnum {
  ALERT = 'mportal_alert',
  ARTICLE = 'mportal_article',
  CONSENT = 'mportal_consent',
  ESERVICE_CATALOG = 'eservicesCatalog',
  MENU = 'mportal_menu',
  PROMOTED = 'mportal_promoted',
  NETIZEN = 'mportal_netizen',
  CORE = 'mportal_core',
  EOFFICE = 'eoffice'
}
