import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ServiceHelper } from '@share/common/helpers/service.helper';
import { ImageZoomComponent } from '@app/template/elements/image-zoom/components/image-zoom/image-zoom.component';

@Injectable({
  providedIn: 'root'
})
export class ImageZoomService extends ServiceHelper {
  private readonly zoomClass: string = '.image-zoom';
  private readonly originalFilterName: string = 'original';

  constructor() {
    super('');
  }

  public setListener() {
    // TODO: it cant find correct dom data, in settimeout works
    const images = document.querySelectorAll(this.zoomClass);
    for (let i = 0; i < images.length; i++) {
      images[i].addEventListener('click', event => this.imagePreview(event.target as HTMLImageElement));
    }
  }

  public imagePreview(image: HTMLImageElement) {
    const src: string = image.src;
    const originalSrc: string = this.getOriginalPath(src);
    // @ts-ignore
    const config: NgbModalOptions = {keyboard: true, backdrop: true, size: 'xl'};
    this.modal.openCustomModal(ImageZoomComponent, {original: originalSrc, alt: image.alt}, config);
  }

  private getOriginalPath(src: string | null): string {
    if (!src) {
      return '';
    }
    let path: string = src.slice(0, src.indexOf('filterName'));
    path += `filterName=${this.originalFilterName}`;
    return path;
  }
}
