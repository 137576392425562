import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatorModule } from '@share/modules/translator/translator.module';
import { RouterModule } from '@angular/router';
import { ElementsModule } from '@share/modules/elements/elements.module';
import { ContainerModule } from '@app/template/layout/modules/container/container.module';
import { GoogleMapModule } from '@share/modules/google-map/google-map.module';
import {
  ContactFormComponent,
  StaticPageComponent,
  StaticPageContactComponent,
  StaticPageDefaultComponent,
  ContactImagesComponent,
  ContactSubheaderComponent,
  SchedulePageComponent
} from '@app/client-core/static-pages/components';
import { ArticleModule } from '@app/client-core/article/article.module';
import { SimpleBoxModule } from '@app/template/elements/simple-box/simple-box.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from '@share/modules/html/checkbox/checkbox.module';
import { InputModule } from '@app/template/elements/input/input.module';
import { TextareaModule } from '@app/template/elements/textarea/textarea.module';
import { HeaderModule } from '@app/template/layout/modules/header/header.module';
import { StaticPageContactDepartmentComponent } from '@app/client-core/static-pages/components/static-page-contact/static-page-contact-department/static-page-contact-department.component';
import { ContactDotsComponent } from '@app/client-core/static-pages/components/static-page-contact/contact-dots/contact-dots.component';
import { BreadcrumbsModule } from '@app/template/layout/modules/breadcrumbs/breadcrumbs.module';

@NgModule({
  declarations: [
    SchedulePageComponent,
    StaticPageContactDepartmentComponent,
    ContactImagesComponent,
    ContactSubheaderComponent,
    ContactFormComponent,
    StaticPageComponent,
    StaticPageDefaultComponent,
    StaticPageContactComponent,
    ContactDotsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ElementsModule,
    TranslatorModule,
    ContainerModule,
    GoogleMapModule,
    ArticleModule,
    SimpleBoxModule,
    ReactiveFormsModule,
    CheckboxModule,
    InputModule,
    TextareaModule,
    HeaderModule,
    BreadcrumbsModule
  ],
  exports: [StaticPageComponent]
})
export class StaticPagesModule {}
