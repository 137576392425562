import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ToastrServiceCustom } from '@share/common/services/toastr.service';
import { Router } from '@angular/router';
import { ROUTES_PATHS } from '@app/common/constants/routes-paths';
import { TranslatorService } from '@share/common/services/translator.service';
import { catchError } from 'rxjs/operators';
import { ErrorResponse } from '@share/common/models/http.model';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {
  constructor(private toastr: ToastrServiceCustom, private translator: TranslatorService, private router: Router) {}

  private get internalErrorMessage(): string {
    return this.translator.trans('global.errors.internalError');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {};
    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request).pipe(catchError(httpErrorResponse => this.handleError(httpErrorResponse)));
  }

  private handleError(httpErrorResponse: any) {
    let error: { error: { errors: Array<ErrorResponse> } } = { error: { errors: [] } };
    if (httpErrorResponse instanceof HttpErrorResponse) {
      this.handleErrorStatus(httpErrorResponse);
      const message = this.getMessage(httpErrorResponse);
      this.toastr.error(message.title);
      error = { error: { errors: [message] } };
    }
    return throwError(httpErrorResponse);
  }

  private getMessage(response: HttpErrorResponse): { title: string; id: string } {
    let title: string = '';
    try {
      const [error] = response.error.errors;
      title = error.title;
    } catch (e) {
      title = this.internalErrorMessage;
    }
    return { title, id: '' };
  }

  private handleErrorStatus(httpErrorResponse: HttpErrorResponse) {
    switch (httpErrorResponse.status) {
      case 403:
        this.handleForbidden();
        break;
      case 412:
      case 0:
        this.goToNotFoundPage();
        this.router.navigate([ROUTES_PATHS.unknownErrorPage.root]);
        break;
    }
  }

  private handleForbidden() {
    if (!this.router.isActive('', false)) {
      this.router.navigate(['']);
    }
  }

  private goToNotFoundPage() {
    this.router.navigate([ROUTES_PATHS.unknownErrorPage.root]);
  }
}
