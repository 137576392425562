import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractData } from '@share/common/models/http';

@Component({
  selector: 'app-article-list-item',
  styleUrls: ['article-list-item.component.scss'],
  templateUrl: 'article-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleListItemComponent {
  @Input() file: string;
  @Input() title: string;
  @Input() categories: AbstractData<{ title: string; slug: string }>[];
  @Input() publishedFrom: string;
  @Input() description: string;
  @Input() slug: string;
  @Input() id: string;
  @Input() link: string;
}
