import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from '@app/client-core/search/services/search.service';
import { SearchItem } from '@app/client-core/search/models/search-item.model';
import { SearchType } from '@app/template/layout/modules/header/interfaces/subheader-config.interface';
import { BreadcrumbsService } from '@app/template/layout/modules/header/services/breadcrumbs.service';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { finalize, first, map, takeUntil, tap } from 'rxjs/operators';
import { PaginationService } from '@app/template/elements/pagination/services/pagination.service';
import { FacadeService } from '@app/common/services/facade.service';
import { SearchResultsSubheaderComponent } from '@app/client-core/search/components/search-results-subheader/search-results-subheader.component';
import { Subject } from 'rxjs';
import { SearchHelperService } from '@app/client-core/search/search-helper.service';
import { SUBHEADER_INJECTION_TOKEN } from '@app/client-core/search/subheader-injection-token';
import { ResponseArray } from '@share/common/models/http';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  searchedWord: string;
  data: { articles: SearchItem[]; pages: SearchItem[] } = { articles: [], pages: [] };
  type: SearchType;
  pages: number = 1;
  count: number = 20;
  getLoading: boolean;
  getError: boolean;
  destroyed$: Subject<boolean> = new Subject<boolean>();

  private searchMetadata: Subject<{ amount: number; keyword: string }> = new Subject<{
    amount: number;
    keyword: string;
  }>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private breadcrumbsService: BreadcrumbsService,
    private paginationService: PaginationService,
    private facadeService: FacadeService,
    private injector: Injector,
    private searchHelperService: SearchHelperService,
    private cdr: ChangeDetectorRef
  ) {
    this.setSubheader();
    this.setBreadcrumbs();
  }

  private setSubheader() {
    this.facadeService.subheader.setConfig({
      component: SearchResultsSubheaderComponent,
      injector: Injector.create({
        providers: [
          {
            provide: SUBHEADER_INJECTION_TOKEN,
            useValue: this.searchMetadata
          }
        ],
        parent: this.injector
      })
    });
    this.facadeService.subheader.config.displayBreadcrumbs.next(true);
  }

  public ngOnInit() {
    this.setPageListener();
    window.scrollTo(0, 0);
  }

  setLoader(value: boolean) {
    this.getLoading = value;
    this.cdr.detectChanges();
  }

  public searchRequest(page: number) {
    this.clearData();
    this.setLoader(true);
    this.searchService
      .getFullList(this.searchedWord, this.type, page, this.count)
      .pipe(
        first(),
        tap(response => (this.searchService.amount = response.data.length)),
        finalize(() => this.setLoader(false))
      )
      .subscribe(response => this.onResponseSuccess(response));
  }

  onResponseSuccess(response: ResponseArray<SearchItem>) {
    this.clearData();
    this.searchMetadata.next({ amount: response?.meta?.amount || 0, keyword: this.searchedWord });
    response.data?.forEach((item: any) => {
      if (item.type === 'Page') {
        this.data = { articles: [...this.data.articles], pages: [...this.data.pages, item] };
      } else if (item.type === 'CmsArticle') {
        this.data = { articles: [...this.data.articles, item], pages: [...this.data.pages] };
      }
    });
    const meta = response.meta;
    if (meta && typeof meta.pages === 'number') {
      this.pages = meta.pages;
    }
  }

  identify(index: number) {
    return index;
  }

  private clearData() {
    this.data = {
      articles: [],
      pages: []
    };
  }

  navigateTo(url: string, isExternal: boolean) {
    if (isExternal) {
      window.open(url);
    } else {
      this.facadeService.router.navigate([url]);
    }
  }

  private setBreadcrumbs() {
    this.breadcrumbsService.forceActive = true;
    this.breadcrumbsService.customBreadcrumbs = [{ title: this.facadeService.translator.trans('search.header') }];
  }

  private setPageListener() {
    combineLatest([this.activatedRoute.params, this.paginationService.currentPage])
      .pipe(
        map(results => ({ data: results[0].data, page: results[1] })),
        takeUntil(this.destroyed$)
      )
      .subscribe(results => {
        this.searchedWord = results.data;
        this.searchRequest(results.page);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.breadcrumbsService.forceActive = false;
  }
}
