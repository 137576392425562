import {
  Compiler,
  ComponentRef,
  Inject,
  Injectable,
  Injector,
  NgModuleFactory,
  Type,
  ViewContainerRef
} from '@angular/core';
import { LAZY_WIDGETS } from '@app/common/constants/tokens';

@Injectable({
  providedIn: 'root'
})
export class LazyLoaderService {
  constructor(
    private injector: Injector,
    private compiler: Compiler,
    @Inject(LAZY_WIDGETS) private lazyWidgets: { [key: string]: () => Promise<NgModuleFactory<any> | Type<any>> }
  ) {}

  async load(name: string, container: ViewContainerRef, component?: any): Promise<ComponentRef<any>> {
    const ngModuleOrNgModuleFactory = await this.lazyWidgets[name]();

    let moduleFactory;
    if (ngModuleOrNgModuleFactory instanceof NgModuleFactory) {
      moduleFactory = ngModuleOrNgModuleFactory;
    } else {
      moduleFactory = await this.compiler.compileModuleAsync(ngModuleOrNgModuleFactory);
    }
    const entryComponent = component || (<any>moduleFactory.moduleType).entry;
    const moduleRef = moduleFactory.create(this.injector);

    const compFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(entryComponent);

    return container.createComponent(compFactory);
  }
}
