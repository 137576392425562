import { Component, OnInit } from '@angular/core';
import { SitemapService } from '@app/template/home/services/sitemap.service';
import { ComponentHelper } from '@app/common/helpers/component.helper';
import { MenuModel } from '@app/client-core/menu/models/menu.model';
import { AbstractData } from '@share/common/models/http';
import { SitemapLoaders } from '@app/template/home/enums/sitemap-loaders.enum';
import { SettingsService } from '@app/common/services/settings.service';
import { finalize, first } from 'rxjs/operators';
import { InstitutionModel } from '@app/template/layout/modules/sitemap/models/institution.model';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent extends ComponentHelper implements OnInit {
  sitemap: Array<AbstractData<MenuModel>> = [];
  SitemapLoaders = SitemapLoaders;
  description: string;
  institution: AbstractData<InstitutionModel>;
  constructor(private sitemapService: SitemapService, private settingService: SettingsService) {
    super();
  }

  public ngOnInit() {
    this.loadSitemap();
    this.getInstitutionData();
  }

  getInstitutionData() {
    this.sitemapService
      .getInstitutionData()
      .pipe(first())
      .subscribe(response => (this.institution = response.data));
  }

  public loadSitemap() {
    this.setLoader(SitemapLoaders.GetAll, true);
    this.sitemapService
      .getList()
      .pipe(
        first(),
        finalize(() => this.setLoader(SitemapLoaders.GetAll, false))
      )
      .subscribe(
        response => (this.sitemap = this.getResponseData<MenuModel>(response)),
        () => this.setComponentError()
      );
  }
}
