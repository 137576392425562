<ul>
  <li class="default">
    <button class="btn" (click)="setDefaultFontSize()">
      <span class="sr-only">domyślna czcionka</span>
      <span aria-hidden="true">A</span>
    </button>
  </li>
  <li class="bigger">
    <button class="btn" (click)="setBiggerFontSize()">
      <span class="sr-only">większa czcionka</span>
      <span aria-hidden="true">A</span>
    </button>
  </li>
  <li class="big">
    <button class="btn" (click)="setBigFontSize()">
      <span class="sr-only">największa czcionka</span>
      <span aria-hidden="true">A</span>
    </button>
  </li>
</ul>
