<app-contact-form [title]="data.sectionTwo.title" [subtitle]="data.sectionTwo.content"></app-contact-form>

<section>
  <div class="container">
    <div class="header">
      <h2>{{ data.sectionThree.title }}</h2>
      <div [innerHTML]="data.sectionThree.subtitle | safe: 'html'"></div>
    </div>
  </div>

  <div class="content">
    <div *ngFor="let item of data.sectionFour.contacts.departments; let i = index">
      <div class="background-pattern up" *ngIf="i === 2">
        <svg viewBox="0 0 1440 767" xmlns="http://www.w3.org/2000/svg" fill="none">
          <path
            d="M1444 716.437V67.4488C1444 67.4488 989 0 720.5 0C452 0 -3 67.4488 -3 67.4488V716.437C-3 716.437 411.5 767 720.5 767C1029.5 767 1444 716.437 1444 716.437Z"
          />
        </svg>
      </div>

      <div [class.additionalBackground]="i === 2">
        <ng-container *ngIf="i < 3">
          <div class="container">
            <app-static-page-contact-department
              [data]="item"
              [rightImage]="i % 2"
              [class.simple]="!(item.bigImage && item.smallImage)"
              [special]="i === 2"
            ></app-static-page-contact-department>
          </div>
        </ng-container>
      </div>

      <div class="background-pattern down" *ngIf="i === 2">
        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 767" fill="none">
          <path
            d="M1444 716.437V67.4488C1444 67.4488 989 0 720.5 0C452 0 -3 67.4488 -3 67.4488V716.437C-3 716.437 411.5 767 720.5 767C1029.5 767 1444 716.437 1444 716.437Z"
          />
        </svg>
      </div>
    </div>

    <div class="container">
      <div *ngFor="let item of data.sectionFour.contacts.departments; let i = index" [class.mt-5]="i === 0">
        <ng-container *ngIf="i >= 3">
          <app-static-page-contact-department
            [data]="item"
            [rightImage]="i % 2"
            [class.simple]="
              !((item.bigImage.url || item.bigImage.croppedUrl) && (item.smallImage.url || item.smallImage.croppedUrl))
            "
            [specialTwo]="i === 3"
          ></app-static-page-contact-department>
        </ng-container>
      </div>
    </div>
  </div>
</section>
