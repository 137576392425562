import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WcagModule } from '@share/modules/wcag/wcag.module';
import { ElementsModule } from '@share/modules/elements/elements.module';
import { LogoModule } from '@app/template/layout/modules/logo/logo.module';
import { SearchModule } from '@app/client-core/search/search.module';
import { TranslatorModule } from '@share/modules/translator/translator.module';
import { RouterModule } from '@angular/router';
import {
  A11yFontSizeComponent,
  GoogleTranslatorComponent,
  HeaderContainerComponent,
  LanguageComponent,
  SubheaderComponent,
  SubheaderDescriptionComponent,
  SubheaderTitleComponent
} from './components';
import { BreadcrumbsModule } from '@app/template/layout/modules/breadcrumbs/breadcrumbs.module';
import { HttpClientModule } from '@angular/common/http';
import { MenuModule } from '@app/client-core/menu/menu.module';
import { InputModule } from '@share/modules/html/input/input.module';
import { FormsModule } from '@angular/forms';
import { HighContrastComponent } from './components/high-contrast/high-contrast.component';

@NgModule({
  declarations: [
    LanguageComponent,
    SubheaderTitleComponent,
    SubheaderDescriptionComponent,
    GoogleTranslatorComponent,
    HeaderContainerComponent,
    SubheaderComponent,
    A11yFontSizeComponent,
    HighContrastComponent
  ],
  imports: [
    CommonModule,
    WcagModule,
    LogoModule,
    ElementsModule,
    HttpClientModule,
    SearchModule,
    ElementsModule,
    TranslatorModule,
    RouterModule,
    BreadcrumbsModule,
    MenuModule,
    InputModule,
    FormsModule
  ],
  exports: [HeaderContainerComponent, SubheaderComponent, SubheaderTitleComponent, SubheaderDescriptionComponent]
})
export class HeaderModule {}
