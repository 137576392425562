import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './components/footer/footer.component';
import { TranslatorModule } from '@share/modules/translator/translator.module';
import { ElementsModule } from '@share/modules/elements/elements.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    TranslatorModule,
    ElementsModule
  ],
  exports: [
    FooterComponent
  ]
})
export class FooterModule {
}
