<ng-container *ngIf="pages && pages > 1">
  <div class="clearfix">
    <div class="float-{{position}} mt-4">
      <ngb-pagination
        [collectionSize]="total"
        [pageSize]="perPage"
        [(page)]="currentPage"
        [maxSize]="3"
        [rotate]="true"
        [boundaryLinks]="true"
      >
        <ng-template ngbPaginationFirst>
          <i class="fas fa-caret-left"></i>
          <i class="fas fa-caret-left"></i>
        </ng-template>
        <ng-template ngbPaginationLast>
          <i class="fas fa-caret-right"></i>
          <i class="fas fa-caret-right"></i>
        </ng-template>
        <ng-template ngbPaginationPrevious>
          <i class="fas fa-caret-left"></i>
        </ng-template>
        <ng-template ngbPaginationNext>
          <i class="fas fa-caret-right"></i>
        </ng-template>
        <ng-template ngbPaginationEllipsis>{{'...'}}</ng-template>
        <ng-template ngbPaginationNumber let-currentPage>{{ currentPage }}</ng-template>
      </ngb-pagination>
    </div>
  </div>
</ng-container>
