import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PromotedService } from '@app/template/home/services/promoted.service';
import { ComponentHelper } from '@app/common/helpers/component.helper';
import { Promoted } from '@app/template/elements/promoted/models/promoted.model';
import { AbstractData } from '@share/common/models/http';
import { finalize, first } from 'rxjs/operators';

@Component({
  selector: 'app-promoted',
  templateUrl: './promoted.component.html',
  styleUrls: ['./promoted.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotedComponent extends ComponentHelper implements OnInit {
  @Input() data: { title: string; subtitle: string };
  promoted: Array<AbstractData<Promoted>> = [];
  loading: boolean = false;

  constructor(private promotedService: PromotedService, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.loadPromoted();
  }

  loadPromoted() {
    this.setLoading(true);
    this.promotedService
      .getList()
      .pipe(
        first(),
        finalize(() => this.setLoading(false))
      )
      .subscribe(
        response => (this.promoted = this.getResponseData<Promoted>(response)),
        () => this.setComponentError()
      );
  }

  private setLoading(value: boolean) {
    this.loading = value;
    this.cdr.markForCheck();
  }
}
