import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HexagonContentComponent} from './components/hexagon-content/hexagon-content.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    HexagonContentComponent
  ],
  exports: [
    HexagonContentComponent
  ]
})
export class PolygonModule {
}
