import {Injectable} from '@angular/core';
import {ApiService} from '@app/common/services/api.service';
import {Observable} from 'rxjs';
import {ResponseObject} from '@share/common/models/http.model';
import {Institution} from '@app/common/models/institution.model';
import {API} from '@app/common/constants/api.directories';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

  constructor(private apiService: ApiService) {}

  public getCurrent(): Observable<ResponseObject<Institution>> {
    return this.apiService.get<Institution>(API.INSTITUTION.ROOT);
  }
}
