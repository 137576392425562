import { ComponentHelper } from '@app/common/helpers/component.helper';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BreadcrumbsService } from '@app/template/layout/modules/header/services/breadcrumbs.service';
import { BreadcrumbsLoaders } from '@app/template/layout/modules/header/enums/breadcrumbs-loaders.enum';
import { Breadcrumbs } from '@app/template/layout/modules/header/models/breadcrumbs.model';
import { takeUntil } from 'rxjs/operators';
import { AbstractData } from '@share/common/models/http';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent extends ComponentHelper implements OnInit {
  public breadcrumbs: Array<AbstractData<Breadcrumbs>> = [];
  public shortVersion: boolean = false;
  public minLengthForShortVersion: number = 3;

  @Output() readonly loaded: EventEmitter<null> = new EventEmitter<null>();

  constructor(private breadcrumbsService: BreadcrumbsService, private cdr: ChangeDetectorRef) {
    super();
  }

  public get breadcrumbsExists(): boolean {
    return Array.isArray(this.breadcrumbs) && this.breadcrumbs.length > 0;
  }

  public ngOnInit() {
    this.setReloadListener();
    this.setClearListener();
  }

  private loadBreadcrumbs() {
    this.setLoader(BreadcrumbsLoaders.Get, true);
    this.subscriptions.add(
      this.breadcrumbsService.getList(this.url).subscribe(
        response => {
          this.breadcrumbs = this.getResponseData<Breadcrumbs>(response);
          this.shortVersion = this.breadcrumbs.length > this.minLengthForShortVersion;
          this.cdr.detectChanges();
          this.loaded.emit();
        },
        () => {}
      )
    );
  }

  private setReloadListener() {
    this.breadcrumbsService.reload.pipe(takeUntil(this.destroyed$)).subscribe(reload => {
      if (reload && !this.breadcrumbsService.forceActive) {
        this.breadcrumbsService.customBreadcrumbs = [];
        this.loadBreadcrumbs();
        this.cdr.detectChanges();
      }
    });
  }

  private setClearListener() {
    this.breadcrumbsService.clear.pipe(takeUntil(this.destroyed$)).subscribe(clear => {
      if (clear) {
        this.breadcrumbs = [];
        this.cdr.detectChanges();
        this.breadcrumbsService.clear.next(false);
      }
    });
  }

  public get customBreadcrumbs(): Array<{ title: string; url?: string }> {
    return this.breadcrumbsService.customBreadcrumbs;
  }

  private get url(): string {
    return this.breadcrumbsService.getParsedUrl(this.service.router.url);
  }

  public getLink(index: number): Array<string> {
    const segments = this.breadcrumbsService.generateLink(this.breadcrumbs, index);

    if (segments.length > 1) {
      return [...segments];
    }

    return ['/'];
  }

  public expandBreadcrumbs(event: Event) {
    event.preventDefault();
    this.shortVersion = false;
    this.cdr.detectChanges();
  }
}
