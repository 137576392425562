import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { finalize, first } from 'rxjs/operators';
import { AbstractData } from '@share/common/models/http';
import { Article } from '@app/client-core/article/models/article.model';
import { ArticlesService } from '@app/client-core/article/services/articles.service';
import { FacadeService } from '@app/common/services/facade.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-article-list-random',
  templateUrl: 'article-list-random.component.html',
  styleUrls: ['article-list-random.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleListRandomComponent implements OnInit {
  @Input() except: string;

  articleList: Array<AbstractData<Article>> = [];
  pages: number = 1;
  loading: boolean;
  getError: boolean;

  constructor(
    private articlesService: ArticlesService,
    private facadeService: FacadeService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  setLoader(value: boolean) {
    this.loading = value;
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.loadArticles();
  }

  loadArticles() {
    this.setLoader(true);
    this.articlesService
      .getRandomArticles(this.except)
      .pipe(
        first(),
        finalize(() => this.setLoader(false))
      )
      .subscribe(
        response => (this.articleList = response.data),
        () => (this.getError = true)
      );
  }
}
