import { StaticPageComponent } from '@app/client-core/static-pages/components/static-page/static-page.component';
import { ArticleListComponent } from '@app/client-core/article/components/article-list/article-list.component';
import { ThemesEnum } from '@app/common/models/themes.enum';
import { ComponentsInterface } from '@app/client-core/dynamic-content/interfaces/components.interface';
import { OverlayComponent } from '@app/template/home/components/overlay/overlay.component';
import { ArticleComponent } from '@app/client-core/article/components';

export const COMPONENTS: ComponentsInterface = {
  main: { name: OverlayComponent, module: ThemesEnum.CORE, className: 'OverlayComponent' },
  static: { name: StaticPageComponent, module: ThemesEnum.CORE, className: 'StaticPageComponent' },
  articlecategory: { name: ArticleListComponent, module: ThemesEnum.ARTICLE, className: 'ArticleListComponent' },
  article: { name: ArticleComponent, module: ThemesEnum.ARTICLE, className: 'ArticleComponent' }
};

export enum ThemeName {
  Basic = 'podstawowy'
}

export const HOME_COMPONENTS: ComponentsInterface = {
  [ThemeName.Basic]: { name: OverlayComponent, module: ThemesEnum.CORE, className: 'OverlayComponent' }
};
