import { AfterViewInit, Component, Injector } from '@angular/core';
import { ComponentHelper } from '@app/common/helpers/component.helper';
import { SubheaderConfig } from '@app/template/layout/modules/header/interfaces/subheader-config.interface';
import { BreadcrumbsService } from '@app/template/layout/modules/header/services/breadcrumbs.service';
import { NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { SettingsService } from '@app/common/services/settings.service';
import { DynamicContentService } from '@app/client-core/dynamic-content/service/dynamic-content.service';
import { Observable } from 'rxjs';
import { HomeService } from '@app/template/home/services/home.service';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent extends ComponentHelper implements AfterViewInit {
  active: boolean = false;
  isHome: boolean = false;

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private settings: SettingsService,
    private dynamicContentService: DynamicContentService,
    private homeService: HomeService
  ) {
    super();
    this.setHome();
  }

  get contentLoaded(): Observable<boolean> {
    return this.dynamicContentService.loaded$;
  }

  get isHomePreview(): Observable<boolean> {
    return this.homeService.isHomePreview$;
  }

  private setHome() {
    this.service.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe(event => {
        this.isHome = event.url === '/';
      });
  }

  get component() {
    return this.service.subheader.config.component;
  }

  get config(): SubheaderConfig {
    return this.service.subheader.config;
  }

  get myInjector(): Injector | null | undefined {
    return this.service.subheader.config.injector;
  }

  get displayBreadcrumbs() {
    return this.service.subheader.config.displayBreadcrumbs;
  }

  get forceShow() {
    return this.service.subheader.config.forceShow;
  }

  get categories(): { title: string; slug: string }[] | undefined {
    return this.service.subheader.config.categories;
  }

  get publishedDate(): string | undefined {
    return this.service.subheader.config.publishedDate;
  }

  get image(): string | undefined {
    return this.service.subheader.config.image;
  }

  get hideDots(): boolean {
    return this.service.subheader.config.hideDots || false;
  }

  ngAfterViewInit(): void {
    this.breadcrumbsService.reload.next(true);
  }

  isArray(categories: { title: string; slug: string }[] | string | undefined): boolean {
    return Array.isArray(categories);
  }
}
