import { Injectable } from '@angular/core';
import { API } from '@app/common/constants/api.directories';
import { ApiService } from '@app/common/services/api.service';
import { Observable } from 'rxjs';
import { BootstrapModel } from '@app/common/models/bootstrap.model';
import { ResponseObject } from '@share/common/models/http';
import { ThemesEnum } from '@app/common/models/themes.enum';

@Injectable({
  providedIn: 'root'
})
export class SettingsService<T extends BootstrapModel = BootstrapModel> {
  variables: T;

  constructor(private apiService: ApiService) {}

  load(): Promise<ResponseObject<T>> {
    return this.apiService.get<T>(API.SETTINGS.ROOT).toPromise();
  }

  loadDefaultVariables(): Observable<T> {
    return this.apiService.assets(API.ASSETS.DEFAULT_SETTINGS);
  }

  isModuleActive(theme: ThemesEnum): boolean {
    return this.variables.theme.modules.includes(theme);
  }
}
