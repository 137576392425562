import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-schedule-page',
  templateUrl: 'schedule-page.component.html',
  styleUrls: ['schedule-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulePageComponent {
  @Input() data: {
    content: {
      boxPhone: string;
      boxSchedule: string;
      boxTitle: string;
      boxSubtitle: string;
      subtitle: string;
      schedule: {date: string, cities: string}[];
    };
  };
}
