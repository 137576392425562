import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadingSectionComponent } from './components/heading-section/heading-section.component';
import {PolygonModule} from '@app/template/elements/polygon/polygon.module';

@NgModule({
  declarations: [
    HeadingSectionComponent
  ],
  exports: [
    HeadingSectionComponent
  ],
  imports: [
    CommonModule,
    PolygonModule
  ]
})
export class HeadingModule { }
