<article class="box">
  <a [routerLink]="[link]">
    <div class="image">
      <div class="image">
        <img *ngIf="file" [src]="file" [alt]="title" />
        <app-logo
          *ngIf="!file"
          class="image-placeholder"
          [id]="'no-image-for-' + id"
        ></app-logo>
      </div>

      <div class="categories">
        <span class="badge badge-primary" *ngFor="let category of categories">{{ category.attributes.title }}</span>
      </div>
    </div>

    <div class="content">
      <span class="published">
        <i class="fas fa-calendar"></i>
        {{ publishedFrom | date: 'shortDate':'':'pl' }}
      </span>
      <h3>{{ title }}</h3>
      <p>
        {{ description }}
      </p>
    </div>
  </a>
</article>
