import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationService } from '../../services/pagination.service';

@Component({
  selector: 'shared-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {

  @Input() public perPage: number = 10;
  @Input() public pages: number = 1;
  @Input() public position: 'left' | 'right' = 'right';
  @Output() readonly changed: EventEmitter<number> = new EventEmitter<number>();

  constructor(private paginationService: PaginationService) {}


  public get total(): number {
    return this.perPage * this.pages;
  }

  public set currentPage(value: number) {
    this.paginationService.page = value;
    this.changed.emit(value);
  }

  public get currentPage(): number {
    return this.paginationService.page;
  }


}
