import { AfterContentChecked, Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewEncapsulation } from '@angular/core';
import { AccPanelChangeEvent } from '../../interfaces/accordion-panel-change-event.model';
import { AccPanelDirective } from '../../directives/accordion-panel.directive';
import { ComponentHelper } from '@app/common/helpers/component.helper';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent extends ComponentHelper implements AfterContentChecked {
  @ContentChildren(AccPanelDirective) panels: QueryList<AccPanelDirective>;
  @Input() activeIds: string[] = [];
  @Output() readonly panelChange = new EventEmitter<AccPanelChangeEvent>();

  constructor() {
    super();
  }

  private _findPanelById(panelId: string): AccPanelDirective | undefined {
    return this.panels.find(p => p.id === panelId);
  }


  private _updateActiveIds() {
    this.activeIds = this.panels.filter(panel => panel.isOpen && !panel.disabled).map(panel => panel.id);
  }

  private _changeOpenState(panel: AccPanelDirective, nextState: boolean, target: EventTarget) {
    if (panel && !panel.disabled && panel.isOpen !== nextState) {
      let defaultPrevented = false;

      this.panelChange.emit({
        panelId: panel.id,
        nextState: nextState,
        target,
        prevent: () => { defaultPrevented = true; }
      });

      if (!defaultPrevented) {
        panel.isOpen = nextState;

        this._updateActiveIds();
      }
    }
  }

  toggle(panelId: string, target: EventTarget) {
    const panel = this._findPanelById(panelId);
    if (panel) {
      this._changeOpenState(panel, !panel.isOpen, target);
    }
  }

  isPanelActive(panelId: string): boolean {
    return this.activeIds && this.activeIds.indexOf(panelId) !== -1;
  }

  expandAll() {
    this.panels.forEach(panel => {
      if (this.activeIds.indexOf(panel.id) === -1) {
        this.activeIds.push(panel.id);
      }
    });
  }

  ngAfterContentChecked() {
    this.panels.forEach(panel => panel.isOpen = !panel.disabled && this.activeIds.indexOf(panel.id) > -1);
  }

}
