import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ComponentHelper } from '@app/common/helpers/component.helper';
import { SettingsService } from '@app/common/services/settings.service';
import { LazyLoaderService } from '@app/common/services/lazy-loader.service';
import { ROUTES_PATHS } from '@app/common/constants/routes-paths';
import { Router } from '@angular/router';
import { AbstractData } from '@share/common/models/http';
import { finalize, first } from 'rxjs/operators';
import { HomeService } from '@app/template/home/services/home.service';
import { PagesService } from '@app/common/services/pages.service';
import { PageModel } from '@app/common/models/page.model';
import { HomeModel } from '@app/template/home/models/home.model';

@Component({
  selector: 'app-home',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayComponent extends ComponentHelper implements OnInit, AfterViewInit {
  @ViewChild('businessModules', { read: ViewContainerRef }) businessModules: ViewContainerRef;
  @Input() data: AbstractData<PageModel<{pageType: '', content: HomeModel}>>;
  loading: boolean = true;

  constructor(
    private settingsService: SettingsService,
    private lazyLoaderService: LazyLoaderService,
    private pagesService: PagesService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private homeService: HomeService
  ) {
    super();
  }

  ngOnInit() {
    this.service.scrollTo.resetScrollTop();
  }

  private setLoading(value: boolean) {
    this.loading = value;
    this.cdr.markForCheck();
  }

  getMainPage() {
    this.setLoading(true);
    this.pagesService
      .getOne()
      .pipe(
        first(),
        finalize(() => this.setLoading(false))
      )
      .subscribe(
        response => {
          this.data = response.data;
        },
        () => {
          this.router.navigate(['/', ROUTES_PATHS.notFoundPage.root]);
        }
      );
  }

  ngAfterViewInit() {
    if (!this.data) {
      this.getMainPage();
    } else {
      this.loading = false;
      this.homeService.isHomePreview = true;
    }
  }
}
