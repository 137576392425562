import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageZoom } from '@app/template/elements/image-zoom/interfaces/image-zoom.interface';
import { ComponentHelper } from '@app/common/helpers/component.helper';

@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.component.html'
})
export class ImageZoomComponent extends ComponentHelper {
  @Input() public data: ImageZoom;
  public loading: boolean = true;
  public placeholder: boolean = false;

  constructor(private activeModal: NgbActiveModal) {
    super();
  }

  public close() {
    this.activeModal.close();
  }

  public onLoad() {
    this.loading = false;
  }

  public onError() {
    this.onLoad();
    this.placeholder = true;
  }
}
