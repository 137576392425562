export enum Bundle {
  EServices = 'EServices',
  User = 'CmsUser',
  Article = 'CmsArticle',
  Banner = 'CmsBanner',
  BusinessModules = 'CmsBusinessModule',
  Settings = 'Setting',
  Alert = 'CmsAlert',
  Promoted = 'CmsPromoted',
  Partners = 'CmsPartners',
  Sitemap = 'CmsSitemap',
  Breadcrumbs = 'Breadcrumbs',
  StaticPages = 'StaticPages',
  Preview = 'preview'
}
