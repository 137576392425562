import { Injectable } from '@angular/core';
import { RequestOptionsModel } from '@share/common/interfaces/request-options.interface';
import { AppConfigCMS } from '@share/common/interfaces/app-config.interface';

declare var APP_CONFIG: AppConfigCMS;

@Injectable({
  providedIn: 'root'
})
export class ApiPreprocessorService {

  private readonly URL = `${APP_CONFIG.API_URL}/${APP_CONFIG.PREFIX}`;

  prepareUrl(endpoint: string): string {
    if (!endpoint.includes(this.URL)) {
      return `${this.URL}/${endpoint}`;
    }

    return endpoint;
  }

  build(bundle: string, data: object | Array<object>, options: RequestOptionsModel = {}): any {
    const request: any = this.createBaseRequest(bundle, data, options);

    if (!!options) {
      if (options.jsonapi) {
        request.jsonapi = options.jsonapi;
      }
      if (options.meta) {
        request.meta = options.meta;
      }
    }

    return request;
  }

  private createBaseRequest(type: string, data: object | Array<object>, options: RequestOptionsModel): any {
    return {
      data: Array.isArray(data) ? data : {type: type, attributes: data, id: options.id || '0'},
      jsonapi: { // TODO: remove
        version: '1.0'
      },
      meta: {}
    };
  }

}
