import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ArticlesService } from '@app/client-core/article/services/articles.service';
import { AbstractData } from '@share/common/models/http';
import { Article } from '@app/client-core/article/models/article.model';
import { finalize, first, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PagesService } from '@app/common/services/pages.service';
import { DynamicContentService } from '@app/client-core/dynamic-content/service/dynamic-content.service';

@Component({
  selector: 'app-news',
  styleUrls: ['news.component.scss'],
  templateUrl: 'news.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsComponent implements OnChanges {
  news: AbstractData<Article>[];
  loading: boolean;
  getError: boolean;

  @Input() data: {
    title: string;
    subtitle: string;
    category: string;
  };

  constructor(
    private articleService: ArticlesService,
    private cdr: ChangeDetectorRef,
    private pageService: PagesService,
    private router: Router,
    private dynamicContentService: DynamicContentService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.currentValue) {
      this.getNews();
    }
  }

  setLoader(value: boolean) {
    this.loading = value;
    this.cdr.detectChanges();
  }

  test() {
    this.pageService.getOne();
  }

  getNews() {
    this.setLoader(true);
    this.pageService
      .getOne(this.data.category)
      .pipe(
        first(),
        finalize(() => this.setLoader(false))
      )
      .subscribe(
        response => this.getMetadata(response.data.id),
        error => (this.getError = true)
      );
  }

  getMetadata(url: string) {
    this.dynamicContentService
      .getPageMetadata(url)
      .pipe(switchMap(metadata => this.articleService.getList(1, 4, metadata.meta?.objectId)))
      .subscribe(news => {
        this.news = news.data;
        this.cdr.detectChanges();
      });
  }

  showMoreClicked() {
    this.router.navigate(['aktualnosci']);
  }

  readMoreClicked(item: AbstractData<Article>) {
    this.router.navigate(['aktualnosci', item.attributes.slug]);
  }
}
