import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MenuModel } from '@app/client-core/menu/models/menu.model';
import { ResponseArray } from '@share/common/models/http';
import { API } from '@app/common/constants/api.directories';
import { ApiService } from '@app/common/services/api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  isMobileMenuActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService, private router: Router) {
  }

  public getList(type?: string): Observable<ResponseArray<MenuModel>> {
    return this.apiService.getAll(`${API.MENU.ROOT}${type ? `/${type}` : ''}`);
  }

  public navigate(type: string, url: string): Promise<boolean> {
    if (type === 'link') {
      return new Promise((resolve) => {
        window.open(url);
        resolve(true);
      });
    } else {
      if (!url || url.includes('null')) {
        return this.router.navigate(['/']);
      } else {
        return this.router.navigateByUrl('/' + url);
      }
    }
  }

  public isActive(url: string): boolean {
    return this.router.isActive(url, true);
  }

}
