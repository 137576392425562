import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementsModule } from '@share/modules/elements/elements.module';
import { TranslatorModule } from '@share/modules/translator/translator.module';
import { HttpClientModule } from '@angular/common/http';
import { LogoModule } from '@app/template/layout/modules/logo/logo.module';
import { DefaultMenuComponent } from './components/default-menu/default-menu.component';
import { RouterModule } from '@angular/router';
import { PolygonModule } from '@app/template/elements/polygon/polygon.module';
import { MobileMenuComponent } from '@app/client-core/menu/components/mobile-menu/mobile-menu.component';

@NgModule({
  declarations: [MobileMenuComponent, DefaultMenuComponent],
  imports: [CommonModule, HttpClientModule, RouterModule, ElementsModule, TranslatorModule, LogoModule, PolygonModule],
  exports: [DefaultMenuComponent, MobileMenuComponent]
})
export class MenuModule {}
