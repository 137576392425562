import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { SUBHEADER_INJECTION_TOKEN } from '@app/client-core/search/subheader-injection-token';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-results-subheader',
  templateUrl: 'search-results-subheader.component.html',
  styleUrls: ['search-results-subheader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultsSubheaderComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  amount: number;
  keyword: string;

  constructor(
    @Inject(SUBHEADER_INJECTION_TOKEN) data: Observable<{ amount: number; keyword: string }>,
    private cdr: ChangeDetectorRef
  ) {
    data.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.amount = data.amount;
      this.keyword = data.keyword;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
