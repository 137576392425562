import {Injectable} from '@angular/core';
import {ServiceHelper} from '@share/common/helpers/service.helper';
import {API} from '@app/common/constants/api.directories';
import { BehaviorSubject, Observable } from 'rxjs';
import {SearchType, SearchTypeNames} from '@app/template/layout/modules/header/interfaces/subheader-config.interface';
import {HttpParams} from '@angular/common/http';
import {AbstractData} from '@share/common/models/http.model';
import {SelectModel} from '@share/modules/html/common/interfaces/select-model.interface';
import {Bundle} from '@app/common/enums/server-bundles.enum';

@Injectable({
  providedIn: 'root'
})
export class SearchService extends ServiceHelper {

  amount: number = 0;
  isActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    super('');
  }

  public getList(keyword: string, searchType: SearchType): Observable<any> {
    let params: HttpParams = new HttpParams().set('contains', keyword);

    if (!!searchType) {
      params = params.set('inside', searchType);
    }

    return this.getAll(API.SEARCH.ROOT, {http: {params}});
  }

  public getFullList(keyword: string, searchType: SearchType, page: number, count: number): Observable<any> {
    let params: HttpParams = new HttpParams();

    params = params.set('page', page.toString());
    params = params.set('count', count.toString());
    params = params.set('contains', keyword);

    if (!!searchType) {
      params = params.set('inside', searchType);
    }

    return this.getAll(API.SEARCH.FULL, {http: {params}});
  }

  public getAvailableSearchTypes(): Array<AbstractData<SelectModel>> {
    return [
      {
        id: SearchType.Default,
        type: Bundle.Alert,
        attributes: {
          title: SearchTypeNames.Default
        }
      },
      {
        id: SearchType.EServices,
        type: Bundle.Alert,
        attributes: {
          title: SearchTypeNames.EServices
        }
      }
    ];
  }
}
