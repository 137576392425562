import { InputTypeEnum } from '@share/modules/html/input/enums/input-types.enum';
import { TranslatorService } from '@share/common/services/translator.service';

export function getContactFormConfig(translatorService: TranslatorService): { [id: string]: any } {
  return {
    senderName: {
      id: 'senderName',
      type: InputTypeEnum.Text,
      label: translatorService.trans('contact.form.name')
    },
    senderEmail: {
      id: 'senderEmail',
      type: InputTypeEnum.Text,
      label: translatorService.trans('contact.form.email')
    },
    content: {
      id: 'content',
      type: InputTypeEnum.Text,
      label: translatorService.trans('contact.form.message')
    }
  };
}
